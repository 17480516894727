import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import ChatMessageItem from 'components/menu/copilot/ChatMessageItem';
import ChatInput from 'components/menu/copilot/ChatInput';
import {CopilotContext} from './CopilotProvider';
import {IChatHistory, ICopilotMessage} from './types';

import Tooltip from 'components/common/Tooltip';

import CopilotLeftDate from 'components/menu/copilot/CopilotLeftDate';
import {useReactFlow} from 'reactflow';
import {getUniqueKey} from 'utils/commons';
import dayjs from 'dayjs';
import {getWidgetTitle, pcFunc} from 'utils/processCanvas-functions';
import {spreadSheetWidgetData} from 'components/spreadsheet/const';

const Container = styled.div`
  height: 0;
  background-color: #393939;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow:
    0 -5px 10px rgba(0, 0, 0, 0.2),
    0 0 3px rgba(0, 0, 0, 0.7);
  border-top: 1px solid #636363;

  &.dragging {
    border-color: #7ccbe3;
  }
`;

const Scroller = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
  }
`;

const ChatHead = styled.div`
  height: 50px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: #393939;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;

  p {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
  }
  Button {
    &:hover {
      background-color: #273979;
    }
    &:disabled {
      background-color: #32499a;
    }
  }
`;
const NoHistory = styled.div`
  margin: auto;
  padding: 50px 0;
  color: #c59388;
  font-size: 13px;
`;

type IProps = {
  children: ReactElement;
  isDragging: boolean;
  setSelectedThread: (threadId: string) => void;
  setIsNewChatOpen(isNewChatOpen: boolean): void;
  setCheckHistoryBtn(checkHistoryBtn: boolean): void;
};

function CopilotPrompt({
  children,
  isDragging,
  setSelectedThread,
  setCheckHistoryBtn,
  setIsNewChatOpen
}: IProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const {currentThread, sendQuestion, isNewChat, getRawData} = useContext(CopilotContext);
  const [chatHistory, setChatHistory] = useState<IChatHistory[]>([]);
  const ref = useRef<HTMLDivElement>();

  const scrollToBottom = (): void => {
    setTimeout(() => {
      ref.current.scrollIntoView();
      // ref.current.scrollIntoView({behavior: 'smooth'});
    }, 100);
  };

  const onSend = async (message: ICopilotMessage) => {
    setIsLoading(true);
    try {
      sendQuestion(currentThread.id, message).then(() => {
        setIsLoading(false);
        scrollToBottom();
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentThread?.chat_history) {
      setChatHistory(currentThread.chat_history);
    }

    if (currentThread) {
      setIsLoading(false);
    }
    if (currentThread === null) {
      setChatHistory([]);
    }
  }, [currentThread]);

  const getDateRageString = (): string => {
    if (!currentThread) return null; // 객체가 없는 상태가 있음
    const {data_range_from, data_range_to} = currentThread;
    if (!data_range_from && !data_range_to) return null; // undefiend 가 아닌 공백문자열로 오는 경우도 있음
    const from = data_range_from ? dayjs(parseInt(data_range_from) * 1000).format('YYYY.MM.DD') : '';
    const to = data_range_to ? dayjs(parseInt(data_range_to) * 1000).format('YYYY.MM.DD') : '';
    return `${from} ~ ${to}`;
  };

  const reactFlow = useReactFlow();

  const [isLoadingRawData, setIsLoadingRawData] = useState(false);

  // 클릭한 스레드의 rawData 확인하는 함수 호출
  const openSpreadsheetWidget = async () => {
    setIsLoadingRawData(true);

    const rawData = await getRawData(currentThread?.id);
    const id = getUniqueKey();
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    const [first] = rawData;
    const colHeaders = Object.keys(first);
    const data = rawData?.map(
      (item) => colHeaders.map((header) => (header === 'timestamp' ? item[header] : item[header]))
      //todo: timestamp 값이 string으로 전달됨 * 1000 불필요.

      // colHeaders.map((header) => (header === 'timestamp' ? item[header] * 1000 : item[header]))
    );
    const rowHeaders = rawData?.map((_, index) => index + 1);

    reactFlow.addNodes({
      id,
      type: 'SpreadsheetWidget',
      data: {
        ...spreadSheetWidgetData,
        title: getWidgetTitle({type: 'SpreadsheetWidget', titleData: currentThread?.title}),
        metaData: {
          colHeaders: colHeaders,
          rowHeaders: rowHeaders,
          colWidth: 150,
          data: data,
          colLengths: colHeaders.length,
          rendererType: 'NormalDataRenderer'
        },
        minWidth: 368,
        minHeight: 200
      },
      style: spreadSheetWidgetData.style,
      position: {x: 400, y: 50},
      zIndex: highestZIndex
    });

    setIsLoadingRawData(false);
  };

  const onClick = () => {
    setIsNewChatOpen(true);
  };

  return (
    <Container className={isDragging && 'dragging'}>
      {children}
      <ChatHead>
        <p> Active Chat </p>
        <Tooltip
          type="button"
          content={
            <CopilotLeftDate
              dateRangeTo={currentThread?.data_range_to}
              isLoading={isLoadingRawData}
              onClick={onClick}
              onClickSpreadsheet={openSpreadsheetWidget}
            />
          }
        >
          {getDateRageString()}
        </Tooltip>
      </ChatHead>
      <Scroller>
        {chatHistory.map((msg) => (
          <ChatMessageItem message={msg} key={msg.create_at + msg.text} />
        ))}
        {isLoading === false && chatHistory.length === 0 && currentThread === null && isNewChat === false && (
          <NoHistory>Please click New Chat button or select Chat History.</NoHistory>
        )}
        {isLoading && currentThread === null && <NoHistory>Please wait.</NoHistory>}
        <div ref={ref} />
      </Scroller>
      <ChatInput onSend={onSend} isLoading={isLoading} setCheckHistoryBtn={setCheckHistoryBtn} />
    </Container>
  );
}

export default CopilotPrompt;
