import React, {type DragEvent, ReactElement, useContext, useEffect, useState} from 'react';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import ActionMenuBody from 'components/menu/action/ActionMenuBody';
import {ActionMenuItem, ActionMenuItemLabel} from 'components/menu/action';
import {faArrowProgress} from '@fortawesome/pro-light-svg-icons';
import {useReactFlow} from 'reactflow';
import {getUniqueKey} from 'utils/commons';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {pcFunc} from 'utils/processCanvas-functions';
import {IFile, IMPfdData} from 'api/data-types';
import ActionMenuFileSearchField from 'components/menu/ActionMenuFileSearchField';
import {frozenLayoutModalOptions, noFileModalOptions} from 'components/menu/constants';
import {CommonContext} from 'components/common/CommonProvider';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import FileInfo from 'components/menu/FileInfo';

/**
 * MetaP&ID 의 저장 파일 목록을 표시
 * @constructor
 */
function MetaPfdWidgetList(): ReactElement {
  const [selectedList, setSelectedList] = useState<IFile[]>([]);
  const [hoveredItem, setHoveredItem] = useState<IFile | null>(null);
  const {fileList} = useContext(MetaPfdContext);
  const reactFlow = useReactFlow();
  const searchInputState = useState<string>('');
  const [searchInput] = searchInputState;
  const {showModal} = useContext(CommonContext);
  const {file, isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;

  useEffect(() => {
    if (fileList.length) {
      const metaPfdFile = fileList.filter((item) => item.fileType === 'meta_pfd');
      if (searchInput) {
        const filteredFile = metaPfdFile.filter((item) =>
          item.fileName.toLowerCase().includes(searchInput.toLowerCase())
        );
        setSelectedList(filteredFile);
      } else {
        setSelectedList(metaPfdFile);
      }
    }
  }, [fileList, searchInput]);

  const onSelectItem = (item: IFile): void => {
    if (isFreeze) {
      showModal(frozenLayoutModalOptions);
      return;
    }
    if (!file) {
      showModal(noFileModalOptions);
      return;
    }

    const position = {x: 400, y: 50};
    const id = getUniqueKey();
    const type = 'MetaPfdWidget';
    const data = {
      type,
      mPfdFileData: item,
      icon: faArrowProgress,
      title: `${item?.fileName}`
    };
    const mPfdData = item.stateData as IMPfdData;
    const style = {
      width: mPfdData?.imageInfo?.naturalWidth / 2 || 600,
      height: mPfdData?.imageInfo?.naturalHeight / 2 + 120 || 600,
      minWidth: mPfdData?.imageInfo?.naturalWidth / 2 || 600,
      minHeight: mPfdData?.imageInfo?.naturalHeight / 2 + 120 || 600
    };
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    reactFlow.setNodes((nodes) => nodes.map((item) => ({...item, selected: false})));
    reactFlow.addNodes({id, type, data, style, position, zIndex: highestZIndex, selected: true});
  };

  const onDragStartItem = (e: DragEvent<HTMLDivElement>, item: IFile): void => {
    if (isFreeze || !file) {
      showModal(frozenLayoutModalOptions);
      return;
    }

    const mPfdData = item.stateData as IMPfdData;
    const dragWidgetData = {
      title: `${item?.fileName}`,
      icon: faArrowProgress,
      type: 'MetaPfdWidget',
      style: {
        width: mPfdData?.imageInfo?.naturalWidth / 2 || 600,
        height: mPfdData?.imageInfo?.naturalHeight / 2 + 120 || 600,
        minWidth: mPfdData?.imageInfo?.naturalWidth / 2 || 600,
        minHeight: mPfdData?.imageInfo?.naturalHeight / 2 + 120 || 600
      },
      metaPfd: item
    };
    e.dataTransfer?.setData('application/dataTransfer/widget', JSON.stringify(dragWidgetData));
  };
  return (
    <>
      <ActionMenuHeader main="Meta PFD" sub="(Widget)" description="Add a Meta PFD by Widget" />
      <ActionMenuFileSearchField searchInputState={searchInputState} />
      <ActionMenuBody>
        {selectedList.map((item) => (
          <ActionMenuItem
            key={item._id}
            disabled={isFreeze || !file}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <ActionMenuItemLabel
              // isActive={file._id === item._id}
              icon={faArrowProgress}
              title={item.fileName}
              userName={item.createdUser}
              draggable={!isFreeze || Boolean(file)}
              disabled={isFreeze || !file}
              onClick={() => onSelectItem(item)}
              onDragStart={(e) => onDragStartItem(e, item)}
            />
            <FileInfo item={item} activeType="hover" isActive={hoveredItem?._id === item._id} />
          </ActionMenuItem>
        ))}
      </ActionMenuBody>
    </>
  );
}

export default MetaPfdWidgetList;
