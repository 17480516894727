import {IAppMenu, IWidgetActionMenu} from 'components/menu/types';
import {
  faArrowProgress,
  faBoxDollar,
  faCalculatorSimple,
  faClouds,
  faCubes,
  faFile,
  faGlobe,
  faObjectsColumn,
  faLineChart,
  faNoteSticky,
  faRobot,
  faTableList,
  faVideo,
  faWindowRestore,
  faTableCells
} from '@fortawesome/pro-light-svg-icons';

export const PROCESS_CANVAS_FILE_EXTENSION = '.pmvpc';
export const META_PFD_FILE_EXTENSION = '.pmvmp';

export const pcMenuList: IAppMenu[] = [
  {
    id: 'process-canvas',
    name: 'Layout',
    description: 'Select a Process Canvas Layout',
    icon: faFile
  },
  {
    id: 'mpfd',
    name: 'Meta PFD',
    description: 'Add a Meta PFD Widget',
    icon: faArrowProgress
  },
  {
    id: 'widget',
    name: 'Widget',
    description: 'Add a Widget',
    icon: faWindowRestore
  },
  {
    id: 'copilot',
    name: 'Copilot',
    description: 'Use Process Canvas with Copilot',
    icon: faRobot
  }
];

export const mpfdMenuList: IAppMenu[] = [
  {
    id: 'mpfd-files',
    name: 'Layout',
    description: 'Select a Meta PFD Layout Files',
    icon: faFile
  }
];

export const managementMenuList: IAppMenu[] = [
  {
    id: 'manage',
    name: 'Manage',
    description: 'Select a manage page for handling',
    icon: faFile
  }
];

const mpfdActionMenuList = [
  {
    id: 'mpfd-annotation',
    fileName: 'Region',
    fileType: 'region',
    content: 'Add/Drag Region in Meta PFD'
  },
  {
    id: 'mpfd-point',
    fileName: 'Point',
    fileType: 'point',
    content: 'Add/Drag Point in Meta PFD'
  },
  {
    id: 'mpfd-line',
    fileName: 'Point',
    fileType: 'point',
    content: 'Add/Drag Line in Meta PFD'
  }
];

export const widgetIdList = [
  'sticky',
  'time-series',
  '3d-landscape',
  'weather',
  'commodity',
  'youtube',
  'web',
  'datasheet',
  'hmb',
  'python-editor',
  'sample',
  'jmp'
];

export type WidgetIdTypes = (typeof widgetIdList)[number];

/**
 * Widget Action Menu 의 메뉴를 표현하는 목록이면서
 * widget 을 react flow 위에 렌더링 할 때 필요한 주요 정보를(제목 등) 제공 한다
 * IWidgetData 은 import {Node} from 'reactflow'; type 의 첫 번째 generic 인 data?: T 형이 된다 = Node<IWidgetData>(중요함)
 */
export const widgetList: IWidgetActionMenu[] = [
  {
    id: 'sticky',
    title: 'Sticky Note',
    description: 'Make a Note',
    icon: faNoteSticky,
    type: 'StickyNoteWidget',
    style: {
      width: 320,
      height: 320,
      minWidth: 260,
      minHeight: 260
    }
  },
  {
    id: 'hmb',
    title: 'HMB Table',
    description: 'Create an HMB Table',
    icon: faTableList,
    type: 'HmbRevisionWidget',
    style: {
      width: 800,
      height: 600,
      minWidth: 352,
      minHeight: 310
    }
  },
  {
    id: 'time-series',
    title: 'Time Series',
    description: 'Plot a time series graph',
    icon: faLineChart,
    type: 'TimeSeriesWidget',
    style: {
      width: 900,
      height: 600,
      minWidth: 900,
      minHeight: 600
    }
  },
  /*  {
    id: '3d-landscape',
    title: '3D Landscape',
    description: '3D Model Viewer',
    icon: faCubes,
    type: 'ThreeLandscapeWidget',
    style: {
      width: 800,
      height: 500,
      minWidth: 800,
      minHeight: 500
    }
  },*/
  /*{
    id: '3dModel',
    title: '3D Model',
    description: '3D Model Viewer',
    icon: faCube,
    type: 'ThreeWidget',
    style: {
      width: 600,
      height: 400
    }
  },*/
  {
    id: 'weather',
    title: 'Weather',
    description: 'Monitor weather conditions',
    icon: faClouds,
    type: 'WeatherWidget',
    style: {
      width: 600,
      height: 700,
      minWidth: 300,
      minHeight: 186
    }
  },
  {
    id: 'commodity',
    title: 'Commodity',
    description: 'Monitor commodity prices',
    icon: faBoxDollar,
    type: 'CommodityWidget',
    style: {
      width: 500,
      height: 620,
      minWidth: 395,
      minHeight: 220
    }
  },
  {
    id: 'youtube',
    title: 'YouTube',
    description: 'Stream a YouTube Video',
    icon: faVideo,
    type: 'YoutubeWidget',
    style: {
      width: 700,
      height: 500,
      minWidth: 350,
      minHeight: 250
    }
  },
  {
    id: 'web',
    title: 'Web Browser',
    description: 'Place a browser to search the web',
    icon: faGlobe,
    type: 'WebWidget',
    style: {
      width: 1000,
      height: 600,
      minWidth: 500,
      minHeight: 300
    }
  },
  /* {
    id: 'jmp',
    title: 'JMP Dashboard',
    description: 'Create a JMP dashboard',
    icon: faObjectsColumn,
    type: 'JmpWidget',
    style: {
      width: 600,
      height: 500,
      minWidth: 400,
      minHeight: 300
    }
  },*/
  {
    id: 'sample',
    title: 'Sample',
    description: 'Create a SampleWidget',
    icon: faTableCells,
    type: 'SampleWidget',
    style: {
      width: 600,
      height: 500,
      minWidth: 200,
      minHeight: 200
    },
    inactive: true
  }
  /*  {
    id: 'datasheet',
    title: 'Datasheet',
    description: 'Create a multi-functional datasheet',
    icon: faTableCells,
    type: 'DatasheetLocalDbWidget',
    style: {
      width: 800,
      height: 600,
      minWidth: 552,
      minHeight: 244
    }
    // inactive: true
  },*/
  /*  {
    id: 'python-editor',
    title: 'Python Editor',
    description: 'Use the integrated python editor',
    icon: faCalculatorSimple,
    type: 'PythonEditorWidget',
    style: {
      width: 1100,
      height: 800,
      minWidth: 1100,
      minHeight: 800
    }
    // inactive: true
  }*/
];

export type PullDownFunctions =
  | WidgetIdTypes
  | 'file'
  | 'create'
  | 'save'
  | 'save-as'
  | 'rename'
  | 'close'
  | 'allow-copy'
  | 'transfer-ownership'
  | 'change-accessibility'
  | 'import'
  | 'export'
  | 'print'
  | 'settings'
  | 'edit'
  | 'copy'
  | 'paste'
  | 'change-pid'
  | 'widget'
  | 'view'
  | 'zoom-in'
  | 'zoom-out'
  | 'zoom-reset'
  | 'zoom-panel'
  | 'freeze'
  | 'minimap'
  | 'smart-align'
  | 'fit-to-screen'
  | 'fullscreen'
  | 'help'
  | 'license'
  | 'about-metaverse'
  | 'about-canvas'
  | 'about-metaPfd'
  | 'delete'
  | 'show-label'
  | 'show-data-table';

export type IPullDownMenu = {
  id: PullDownFunctions;
  title: string;
  description?: string;
  hotkey?: string;
  checked?: boolean;
  underlined?: boolean;
  disabled?: boolean;
  command?: () => void;
  children?: IPullDownMenu[];
};

// 새 레이아웃 router
export const NEW_FILE_ROUTE = 'new-file';

// router 가 new-file 일 경우
export const disableListByNewFile: PullDownFunctions[] = [
  'save-as',
  'rename',
  'export',
  'allow-copy',
  'transfer-ownership',
  'change-accessibility'
];
// router 가 없을 경우 (=열려있는 파일이 없는 경우)
export const disableListByNoneFile: PullDownFunctions[] = [
  ...widgetIdList,
  'save',
  'save-as',
  'rename',
  'close',
  'allow-copy',
  'transfer-ownership',
  'change-accessibility',
  'export',
  'copy',
  'paste',
  'delete',
  'zoom-in',
  'zoom-out',
  'zoom-reset',
  'fit-to-screen',
  'fullscreen',
  'show-label',
  'show-data-table',
  'freeze',
  'zoom-panel',
  'minimap',
  'smart-align',
  'line-detection'
];

// 파일의 owner 가 아닐 경우
export const disableListByNotOwner: PullDownFunctions[] = [
  'save',
  'rename',
  'export',
  'allow-copy',
  'transfer-ownership',
  'change-accessibility'
];

export const disableSaveAsCopy: PullDownFunctions[] = ['save-as'];

export const disableListByFreezeLayout: PullDownFunctions[] = [...widgetIdList, 'copy', 'paste', 'delete'];

// Process Canvas Pull Down Menu
export const processCanvasMenu: IPullDownMenu[] = [
  {
    id: 'file',
    title: 'File',
    children: [
      {
        id: 'create',
        title: 'New'
      },
      {
        id: 'save',
        title: 'Save',
        hotkey: 'Ctrl + s'
      },
      {
        id: 'save-as',
        title: 'Save As Copy',
        hotkey: 'Ctrl + Shift + s',
        disabled: false
      },
      {
        id: 'rename',
        title: 'Rename...'
      },
      {
        id: 'close',
        title: 'Close',
        hotkey: 'Ctrl + e',
        underlined: true
      },
      {
        id: 'allow-copy',
        title: 'Allow Save As Copy'
      },
      {
        id: 'transfer-ownership',
        title: 'Transfer Ownership'
        // disabled: true
      },
      {
        id: 'change-accessibility',
        title: 'Change Accessibility'
      },
      {
        id: 'import',
        title: 'Import'
        // disabled: true
      },
      {
        id: 'export',
        title: 'Export',
        // disabled: true
        underlined: true
      },
      /* {
        id: 'print',
        title: 'Print',
        hotkey: 'Ctrl + p',
        disabled: true
      },*/
      {
        id: 'settings',
        title: 'Settings'
      }
    ]
  },
  {
    id: 'edit',
    title: 'Edit',
    children: [
      {
        id: 'copy',
        title: 'Copy Widget',
        hotkey: 'Ctrl + shift + c',
        disabled: true
      },
      {
        id: 'paste',
        title: 'Paste Widget',
        hotkey: 'Ctrl + shift + v',
        disabled: true
      },
      {
        id: 'delete',
        title: 'Delete Widget',
        hotkey: 'Delete',
        disabled: true
      }
    ]
  },
  {
    id: 'widget',
    title: 'Widget',
    children: widgetList.filter((item) => !item.inactive).map((item) => ({id: item.id, title: item.title}))
  },
  {
    id: 'view',
    title: 'View',
    children: [
      {
        id: 'zoom-in',
        title: 'Zoom In',
        hotkey: 'Ctrl + ='
      },
      {
        id: 'zoom-out',
        title: 'Zoom Out',
        hotkey: 'Ctrl + -'
      },
      {
        id: 'zoom-reset',
        title: 'Zoom Reset',
        hotkey: 'Ctrl + 0'
      },
      {
        id: 'fit-to-screen',
        title: 'Fit to Screen'
      },
      {
        id: 'zoom-panel',
        title: 'Zoom Panel',
        checked: true,
        underlined: true
      },

      /*  {
        id: 'freeze',
        title: 'Freeze Layout',
        checked: false
      },*/
      {
        id: 'fullscreen',
        title: 'Fullscreen',
        hotkey: 'F11'
      },
      {
        id: 'minimap',
        title: 'Minimap',
        checked: true
      },
      {
        id: 'smart-align',
        title: 'Smart Align',
        checked: true
      }
    ]
  },
  {
    id: 'help',
    title: 'Help',
    children: [
      /*      {
        id: 'license',
        title: 'License Information',
        disabled: true
      },*/
      {
        id: 'about-canvas',
        title: 'About Process Canvas'
      },
      {
        id: 'about-metaverse',
        title: 'About ProcessMetaverse™'
      }
    ]
  }
];

// Meta PFD Pull Down Menu
export const metaPfdMenu: IPullDownMenu[] = [
  {
    id: 'file',
    title: 'File',
    children: [
      {
        id: 'create',
        title: 'New'
      },
      {
        id: 'save',
        title: 'Save',
        hotkey: 'Ctrl + s'
      },
      {
        id: 'save-as',
        title: 'Save As Copy',
        hotkey: 'Ctrl + Shift + s',
        disabled: false
      },
      {
        id: 'rename',
        title: 'Rename...'
      },
      {
        id: 'close',
        title: 'Close',
        hotkey: 'Ctrl + e',
        underlined: true
      },
      {
        id: 'allow-copy',
        title: 'Allow Save As Copy'
      },
      {
        id: 'transfer-ownership',
        title: 'Transfer Ownership'
        // disabled: true
      },
      {
        id: 'change-accessibility',
        title: 'Change Accessibility'
      },
      {
        id: 'import',
        title: 'Import'
        // disabled: true
      },
      {
        id: 'export',
        title: 'Export',
        // disabled: true,
        underlined: true
      },
      /* {
        id: 'print',
        title: 'Print',
        hotkey: 'Ctrl + p',
        disabled: true
      },*/
      {
        id: 'settings',
        title: 'Settings'
      }
    ]
  },
  {
    id: 'edit',
    title: 'Edit',
    children: [
      /*     {
        id: 'change-pid',
        title: 'Change PID...',
        disabled: true
      },*/
      {
        id: 'line-detection',
        title: 'Line Detect...'
      }
    ]
  },
  {
    id: 'view',
    title: 'View',
    children: [
      {
        id: 'zoom-in',
        title: 'Zoom In',
        hotkey: 'Ctrl + ='
      },
      {
        id: 'zoom-out',
        title: 'Zoom Out',
        hotkey: 'Ctrl + -'
      },
      {
        id: 'zoom-reset',
        title: 'Zoom Reset',
        hotkey: 'Ctrl + 0'
      },
      {
        id: 'zoom-panel',
        title: 'Zoom Panel',
        checked: true,
        underlined: true
      },
      /*    {
        id: 'freeze',
        title: 'Lock Layout',
        disabled: true
      },*/
      {
        id: 'fullscreen',
        title: 'Fullscreen',
        hotkey: 'F11',
        underlined: true
      },
      {
        id: 'show-label',
        title: 'Show Label',
        checked: false
      },
      {
        id: 'show-data-table',
        title: 'Show Data Table',
        checked: false
      }
    ]
  },
  {
    id: 'help',
    title: 'Help',
    children: [
      /*      {
        id: 'license',
        title: 'License Information',
        disabled: true
      },*/

      {
        id: 'about-metaPfd',
        title: 'About Meta PFD Editor'
      },
      {
        id: 'about-metaverse',
        title: 'About ProcessMetaverse™'
      }
    ]
  }
];

// Canvas 가 Freeze Layout 상태일 때 보여주는 모달 option
export const frozenLayoutModalOptions = {
  title: 'Warning',
  content: 'This Layout file is frozen.\nPlease uncheck the toggle under View > Freeze Layout to use it.'
};

// 파일이 열려 있지 않는 상태에서 사용하는 모달 option
export const noFileModalOptions = {
  title: 'Warning',
  content: 'You have not file.\nPlease load file or new file.'
};

// line detect 를 이미 실행하였을 때 사용하는 모달 option
export const alreadyDetectedModalOptions = {
  title: 'Error',
  content: 'You have already run the line detection tool.',
  confirmLabel: 'Ok'
};

export const onlySvgFileDetectableModalOptions = {
  title: 'Line Detection',
  content: 'Only SVG File can be detected',
  confirmLabel: 'Ok'
};
