import {PropsWithChildren, useCallback, useContext, useEffect, useId, useRef} from 'react';
import {LightningChartContext} from 'components/pc/widgets/timeseries/LightningChartProvider';
import styled from 'styled-components';
import {ITimeSeriesLoader} from 'components/pc/widgets/timeseries/types';
import dayjs from 'dayjs';
import {
  Axis,
  AxisOptions,
  AxisTickStrategies,
  ChartXY,
  ColorHEX,
  emptyFill,
  FillStyle,
  PointLineAreaSeries,
  SolidFill,
  UIBackgrounds,
  UIElementBuilders
} from '@lightningchart/lcjs';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
`;

/**
 *
 * @param nodes
 * @param channelMinHeight
 */
const getYAxisName = (nodes: string[], channelMinHeight?: number): string => {
  let str = nodes[nodes.length - 1];
  if (channelMinHeight < 100) {
    str = str.substring(0, Math.floor(channelMinHeight / 10)) + '⋯';
  }
  return str;
};

const defaultRangeInterval = {
  start: dayjs().subtract(7, 'days').toDate().getTime(),
  end: dayjs().toDate().getTime()
};

// const getIntervalRange = (prevRange)

const getSolidFill = (color: string): FillStyle => {
  return new SolidFill({color: ColorHEX(color)}) as unknown as FillStyle;
};

type IProps = PropsWithChildren & {
  loader: ITimeSeriesLoader;
};

function LightningChartDisplay({loader}: IProps) {
  // const {data} = props;
  const id = useId();
  const lc = useContext(LightningChartContext);
  // const [chartState, setChartState] = useState<{chart: ChartXY; seriesList: PointLineAreaSeries[]}>();
  const chartRef = useRef<undefined | ChartXY>(null);
  const lineSeriesRef = useRef<PointLineAreaSeries[]>([]);
  // 받은 라이브 데이터를 누적해서 쌓아둬야 함 -> color, active, YAxis 가 변경 되면 chart 를 다시 그리는데 이때 최초 받은 데이터와 이 데이터를 합쳐 차트를 표현 한다.
  const liveRecordsRef = useRef({});

  const createChart = useCallback((): ChartXY => {
    chartRef.current?.dispose();
    const container = document.getElementById(id) as HTMLDivElement;
    if (!container || !lc) {
      return;
    }
    const defaultAxisX: AxisOptions = {type: 'linear-highPrecision'};
    const chart = lc.ChartXY({container, defaultAxisX});

    const backgroundStyle = new SolidFill({color: ColorHEX('#292d3e')}) as unknown as FillStyle;
    chart
      .setTitle('')
      .setBackgroundFillStyle(backgroundStyle)
      .setSeriesBackgroundFillStyle(backgroundStyle)
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.DateTime)
      .setInterval(defaultRangeInterval);

    return chart;
  }, [id, lc]);

  useEffect(() => {
    return () => {
      // Destroy chart when component lifecycle ends.
      chartRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    chartRef.current = createChart();
  }, [createChart]);

  useEffect(() => {
    if (!chartRef.current || loader.series.length === 0 || chartRef.current.isDisposed()) return;

    const create = (): void => {
      chartRef.current = createChart();

      // yAxis 객체 묶음
      const yAxisList = [];
      let stackCount = 0;

      const parallelList = loader.series.map((ch) => ch.parallel);
      // series 를 기준으로 만들어야 할 yAxis 를 계산
      lineSeriesRef.current = loader.series.map((channel, index) => {
        if (!channel.active) return null;

        const lineStyle = getSolidFill(channel.color);
        const sharedLineStyle = getSolidFill('#ffffff');

        let axisY: Axis;
        const foundYAxis: Axis = yAxisList[channel.parallel];
        const foundStackInfo = loader.stackInfo?.find((info) => info.parallel === channel.parallel);
        const parallelLength = parallelList.filter((num) => num === channel.parallel).length;
        const channelMinHeight = (chartRef.current.getSizePixels().y - 80) / parallelLength;

        if (foundYAxis && !foundStackInfo?.stacked) {
          axisY = foundYAxis;
          axisY
            .setTitle(axisY?.getTitle() + ', ' + getYAxisName(channel.keys, channelMinHeight))
            .setTitleFillStyle(sharedLineStyle);
        } else {
          // yAxis 를 새로 만들어야 하는 case
          axisY = chartRef.current
            .addAxisY({iParallel: -channel.parallel, iStack: stackCount})
            .setChartInteractionZoomByWheel(false)
            .setChartInteractionPanByDrag(!loader.isLive)
            .setMargins(10, 10)
            .setTitle(getYAxisName(channel.keys, channelMinHeight))
            .setTitleFillStyle(lineStyle)
            .setThickness(80);

          // console.log('>>>> ch', channel.name, count);

          if (!foundYAxis && parallelLength > 1) {
            const {stackInfo, setStackInfo, refreshSeries} = loader;

            const isOn = stackInfo?.find((info) => info.parallel === channel.parallel)?.stacked;
            chartRef.current
              .addUIElement(
                UIElementBuilders.CheckBox.setBackground(UIBackgrounds.None),
                chartRef.current.coordsRelative
              )
              .setPosition({x: 80 * axisY.getParallelIndex() * -1 + 60, y: 22})
              .setText('stack')
              .setTextFillStyle(getSolidFill(isOn ? '#00a6cb' : '#B6B4C0'))
              .setButtonOnFillStyle(getSolidFill('#00a6cb'))
              .setButtonOffFillStyle(getSolidFill('#B6B4C0'))
              .setOn(isOn)
              .setEffect(false)
              .onMouseClick((obj, event, info) => {
                // console.log('stack button:', obj.getOn(), event, info);
                // file 저장을 위해 loader 에 저장. chart 의 업데이트는 여기서 직접 일으킨다.
                setStackInfo((prev) => {
                  return prev
                    .reduce((acc, cur) => {
                      if (!acc.some((info) => info.parallel === cur.parallel)) {
                        acc.push(cur);
                      }
                      return acc;
                    }, [])
                    .map((info) => {
                      if (info.parallel === channel.parallel) {
                        info.stacked = !info.stacked;
                      }
                      return info;
                    });
                });
                refreshSeries();
              });
          }
          stackCount--;
          yAxisList[channel.parallel] = axisY;
        }

        const liveData = liveRecordsRef.current?.[channel.name] || [];

        console.log('>>> liveData', liveData);

        return chartRef.current
          .addPointLineAreaSeries({axisY, dataPattern: 'ProgressiveX'})
          .setName(channel.name)
          .appendJSON(channel.data.map(([timestamp, value]) => ({x: timestamp, y: value})).concat(liveData))
          .setStrokeStyle((stroke) => stroke.setFillStyle(lineStyle))
          .setAreaFillStyle(emptyFill as FillStyle)
          .setPointFillStyle(emptyFill as FillStyle)
          .setEffect(undefined);
      });

      chartRef.current.getDefaultAxisX().fit().setAnimationScroll(false).setChartInteractionPanByDrag(!loader.isLive);
      chartRef.current.getDefaultAxisY().setVisible(false);
    };
    create();

    /*lineSeriesRef.current.forEach((series) => {
      // series.dispose();
      if (series) {
        series.clear();
        series.axisY.dispose();
        series.dispose();
      }
    });*/
  }, [loader.series, createChart]);

  const refineChartPoint = (point: number[]): {x: number; y: number} => {
    const [timestamp, value] = point;
    const x = timestamp * 1000;
    const y = value === null ? value : Number(value);
    return {x, y};
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (loader.isLive) {
      const run = (): void => {
        const end = new Date().getTime();
        const start = end - 600000; // 10분간
        // console.log('<<< ', start, end);
        chartRef.current.getDefaultAxisX().setInterval({start, end, animate: 500});
        timerId = setTimeout(run, 5000);
      };
      run();
    } else {
      // 누적 라이브 데이터를 초기화
      liveRecordsRef.current = {};
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [loader.isLive]);

  useEffect(() => {
    if (!loader?.liveData?.length) return;

    lineSeriesRef.current.forEach((ch) => {
      const name = ch?.getName();
      const found = loader.liveData.find((data) => data.node.join('-') === name);
      if (!found) return;

      const prev = liveRecordsRef.current?.[name] || [];
      const dataset = found.records.map((point) => refineChartPoint(point));
      // 지금까지의 라이브 데이터를 모두 누적하여 보관
      liveRecordsRef.current[name] = prev.concat(dataset);
      ch?.appendJSON(dataset, {x: 'x', y: 'y'});
    });
  }, [loader.liveData]);

  return <Container id={id} />;
}

export default LightningChartDisplay;
