import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {useReactFlow} from 'reactflow';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';
import {
  faBringForward,
  faBringFront,
  faClose,
  faGear,
  faLock,
  faLockOpen,
  faPen,
  faSendBack,
  faSendBackward,
  faSidebarFlip,
  faWindowMinimize
} from '@fortawesome/pro-light-svg-icons';
import {Button} from 'components/forms';
import classNames from 'classnames';
import {toKebabCase} from 'utils/commons';
import {WidgetTypes} from 'components/menu/types';
import Tooltip from 'components/common/Tooltip';
import {Node} from '@reactflow/core/dist/esm/types/nodes';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {pcFunc} from 'utils/processCanvas-functions';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';

const Container = styled.div`
  flex-shrink: 0;
  height: 30px;
  padding: 5px 10px;
  background-color: #e2e7e8;
  display: flex;
  cursor: move;
  position: relative;
  z-index: 10;

  &.sticky-note-widget {
    background-color: #fff7d1;
  }
  &.docked {
    cursor: default;
  }
`;
const InputTitle = styled.input`
  border: none;
  border-bottom: 1px solid #4f5e62;
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  color: ${({theme}) => theme.color.black};
  padding: 5px 0 5px 5px;
  text-overflow: ellipsis;

  &:read-only {
    border: none;
    color: #8a9496;
    background-color: transparent;
    cursor: inherit;
    pointer-events: none;
  }
`;

const WidgetTitle = styled.div`
  height: 30px;
  line-height: 30px;
  color: #8a9496;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //flex: 1;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
  }

  .title-modify-btn {
    margin-left: 5px;
    cursor: pointer;
    opacity: 0;
  }

  &:hover {
    .title-modify-btn {
      opacity: 1;
      transition: all 0.1s;
    }
  }
  small {
    color: #ccc;
  }
`;

const HiddenTitle = styled.span`
  position: absolute;
  margin-left: 19px;
  padding: 5px 0 5px 5px;
  //padding: 5px;
  pointer-events: none;
  font-size: 15px;
  visibility: hidden;
`;
const TitleSuffix = styled.span`
  margin-left: 5px;
  color: ${({theme}) => theme.color.optional};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  cursor: default;
  align-items: center;
`;
const StackOrderLayer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000; // 노드보다 작은 z Index 값을 가지게 될 시에 노드가 먼저 클릭됨
  background-color: #ffffff;
  padding: 5px;
  margin-top: 5px;
  margin-left: -8px;
  gap: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: default;
  svg {
    cursor: pointer;
    &.disabled {
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
    }
  }
`;

const iconColor = '#7C8D91';

const IconDivider = styled.div`
  background-color: #acbcc0;
  width: 1px;
  height: 14px;
  margin: 0 10px;

  // 자신 기준으로 왼쪽에 아무것도 없으면 보일 필요 없음
  &:is(:first-child) {
    display: none;
  }
`;

type IProps = {
  type?: WidgetTypes;
  children?: ReactElement | ReactElement[];
  id: string;

  icon?: IconDefinition;
  title?: string;
  suffix?: string;
  hasStackOrder?: boolean;
  hasDocking?: boolean;
  docked?: boolean;
  selected?: boolean;
  hasMinimized?: boolean;
  onClose?(): void;
  onConfig?(): void;
  onLock?(bool: boolean): void;
  onChangeTitle?(title: string): void;
};

/**
 * Widget 컴포넌트 상단에 위치하는 widget header
 * @param id
 * @param icon
 * @param title
 * @param onStackOrder 기본값은 true
 * @param hasDocking
 * @param docked
 * @param onClose
 * @param onConfig
 * @param onLock
 * @constructor
 */
function WidgetHeader({
  type,
  children,
  id,
  icon,
  title = '',
  suffix,
  hasStackOrder = true,
  hasMinimized = true,
  hasDocking,
  docked,
  selected,
  onClose,
  onConfig,
  onLock,
  onChangeTitle
}: IProps): ReactElement {
  const {canvasEnteredState} = useContext(ProcessCanvasContext);
  const [, setCanvasEntered] = canvasEnteredState;
  const [locked, setLocked] = useState(false);
  const [isShowStack, setIsShowStack] = useState(false);
  const [editable, setEditable] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);
  const [width, setWidth] = useState(0);
  const span = useRef(null);

  // const {onClickMinimize} = useProcessCanvasCommand();
  const reactFlow = useReactFlow();
  const nodes = reactFlow.getNodes();
  const edges = reactFlow.getEdges();
  // const [currentViewItem, setCurrentViewItem] = useState(null);

  const zIndexArray = nodes.map((node) => ({zIndex: node.zIndex, id: node.id})).sort((a, b) => a.zIndex - b.zIndex);
  const selectedNodeZIndexOrder = zIndexArray.findIndex((item) => item.id === id);
  const isTop = nodes.length === selectedNodeZIndexOrder + 1;
  const isBottom = selectedNodeZIndexOrder === 0;
  // 맨 앞 맨 뒤 막기

  const onClickLock = (): void => {
    const bool = !locked;
    setLocked(bool);
    onLock?.(bool);
  };

  const onNodeZIndexChangedEdgeZIndexCalc = (newNodes: Node[], nodeId: string, highestZIndex: number) => {
    const newZIndexOfNode = highestZIndex || 0;
    const relatedEdges = edges
      .filter((item) => item.source === nodeId || item.target === nodeId)
      .map((item) => ({...item, zIndex: newZIndexOfNode, style: {...item?.style, zIndex: newZIndexOfNode}}));
    return edges.map((edge) =>
      relatedEdges.find((relatedEdge) => edge?.id === relatedEdge?.id)
        ? relatedEdges.find((relatedEdge) => edge?.id === relatedEdge?.id)
        : edge
    );
  };

  const onClickStackOrder = (e: MouseEvent, action: 'FRONT' | 'FORWARD' | 'BACK' | 'BACKWARD'): void => {
    // e.stopPropagation();
    const nodeId = id;
    const nodes = reactFlow.getNodes();
    const nodeCount = nodes.length;

    const zIndexArray = nodes.map((node) => ({zIndex: node.zIndex, id: node.id})).sort((a, b) => a.zIndex - b.zIndex);

    const selectedNodeZIndexOrder = zIndexArray.findIndex((item) => item.id === nodeId);
    const selectedNodeZIndex = zIndexArray[selectedNodeZIndexOrder].zIndex;
    const selectedNodeIdx = nodes.findIndex((item) => item.id === zIndexArray[selectedNodeZIndexOrder].id);
    let newNodes = [];
    let newEdges = [];

    switch (action) {
      case 'FRONT': // 맨 앞으로 보내기
        const highestZIndex = zIndexArray[nodeCount - 1].zIndex + 1;
        newNodes = nodes.map((node) => (node.id === nodeId ? {...node, zIndex: highestZIndex} : node));
        newEdges = onNodeZIndexChangedEdgeZIndexCalc(newNodes, nodeId, highestZIndex + 1);
        break;
      case 'BACK': // 맨 뒤로 보내기
        newNodes = nodes.map((node) =>
          node.id === nodeId ? {...node, zIndex: 0} : {...node, zIndex: node.zIndex + 1}
        );
        const lowestZIndex = newNodes.find((node) => node.id === nodeId).zIndex;
        newEdges = onNodeZIndexChangedEdgeZIndexCalc(newNodes, nodeId, lowestZIndex + 1);
        break;
      case 'FORWARD': // 앞으로 보내기
        let forwardZIndex = zIndexArray[selectedNodeZIndexOrder + 1].zIndex;
        let forwardNodeIndex = nodes.findIndex((item) => item.id === zIndexArray[selectedNodeZIndexOrder + 1].id);
        newNodes = nodes.with(forwardNodeIndex, {...nodes[forwardNodeIndex], zIndex: selectedNodeZIndex});
        newNodes = newNodes.with(selectedNodeIdx, {...nodes[selectedNodeIdx], zIndex: forwardZIndex});
        newEdges = onNodeZIndexChangedEdgeZIndexCalc(newNodes, nodeId, forwardZIndex + 1);
        break;
      case 'BACKWARD': // 뒤로 보내기
        let backwardZIndex = zIndexArray[selectedNodeZIndexOrder - 1].zIndex;
        let backwardNodeIndex = nodes.findIndex((item) => item.id === zIndexArray[selectedNodeZIndexOrder - 1].id);
        newNodes = nodes.with(backwardNodeIndex, {...nodes[backwardNodeIndex], zIndex: selectedNodeZIndex});
        newNodes = newNodes.with(selectedNodeIdx, {...nodes[selectedNodeIdx], zIndex: backwardZIndex});
        newEdges = onNodeZIndexChangedEdgeZIndexCalc(newNodes, nodeId, backwardNodeIndex + 1);
        break;
    }
    reactFlow.setNodes(newNodes);
    reactFlow.setEdges(newEdges);
    setIsShowStack(false);
  };

  const removeThisWidget = (): void => {
    reactFlow.deleteElements({nodes: [{id}]});
    onClose?.();
  };
  const onClickClose = (e: MouseEvent): void => {
    e.stopPropagation();
    // reactFlow.setNodes((nodes) => nodes.filter((node) => node.id !== id));
    removeThisWidget();
  };

  const onClickMinimize = (event: MouseEvent): void => {
    event.stopPropagation();
    document.startViewTransition(() => {
      // setCurrentViewItem(nodes.map((item) => item.id === id));
      reactFlow.setNodes((nodes) => nodes.map((item) => (item.id === id ? {...item, hidden: true} : item)));
    });
  };

  /**
   * ReactFlow Pane Click then showStack false
   * Window mouseDown event not working when click react flow pane
   * And also not working on 'Drag Handler' Div Element
   * addEventListener activate once, (when user click 'Widget Body')
   * When user click 'Widget header' setIsShowStack(false)
   * When use click react flow pane, 'selected' will be false, so setIsShowStack(false)
   */
  useEffect(() => {
    if (isShowStack) {
      document.addEventListener(
        'mousedown',
        () => {
          setIsShowStack(false);
        },
        {once: true}
      );
    }
  }, [isShowStack]);

  useEffect(() => {
    if (!selected) {
      setIsShowStack(false);
    }
  }, [selected]);

  const {copy, dock} = useProcessCanvasCommand();
  const [createContextMenu] = useContextMenu();

  const onMouseEvent = (event: MouseEvent): void => {
    event.stopPropagation();
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    // 오른쪽 마우스 클릭을 통한 widget select => copy & paste 동작을 위한 조건
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.selected = true;
          node.zIndex = highestZIndex + 1;
        } else {
          node.selected = false;
        }
        return node;
      })
    );
    const {type} = event as BaseSyntheticEvent;
    setIsShowStack(false);

    if (type === 'contextmenu') {
      const list = [
        {
          label: 'Copy Widget',
          value: 'copy-widget',
          callback: copy
        },
        {
          label: 'Lock Widget',
          value: 'lock-widget',
          disabled: true
        },
        {
          label: 'Minimize Widget',
          value: 'minimize-widget',
          callback() {
            onClickMinimize(event);
            // console.log('>>>>>> minimize widget', title, id);
          }
        },
        {
          label: 'Close Widget',
          value: 'close-widget',
          callback: removeThisWidget
        }
        /*{
          label: 'Comment Here',
          value: 'comment-here',
          disabled: true
        }*/
      ];
      createContextMenu({list, event, title});
    }
  };

  const {setNodes} = useReactFlow();
  const onClickDocking = (): void => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data.docked = !node.data.docked;
          node.hidden = node.data.docked;
          dock(node.data.docked, [id]);
        }
        return node;
      })
    );
  };

  useEffect(() => {
    setCurrentTitle(title);
    if (currentTitle.length === 0) {
      nodes.map((node) => {
        if (node.id === id) {
          node.data.customizedTitle = false;
        }
        return node;
      });
    }
  }, [title]);

  useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [currentTitle]);

  const ref = useRef(null);

  const onClickTitleIcon = () => {
    setEditable(true);
    ref.current.focus();
  };
  const onChangeTitleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setCurrentTitle(newTitle);
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data.title = newTitle;
          node.data.customizedTitle = true;
        }
        return node;
      })
    );
  };

  const onFocus = () => {
    setCanvasEntered(false);
  };

  const onBlurTitle = () => {
    setEditable(false);
    setCanvasEntered(true);
    onChangeTitle?.(currentTitle);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Enter') {
      onBlurTitle();
      onChangeTitle?.(currentTitle);
    }
  };

  return (
    <Container
      className={classNames('widget-header', toKebabCase(type), {docked})}
      onClick={onMouseEvent}
      onContextMenu={onMouseEvent}
    >
      <WidgetTitle>
        <FontAwesomeIcon icon={icon} />
        <HiddenTitle ref={span}>{currentTitle}</HiddenTitle>
        <InputTitle
          ref={ref}
          // autoFocus
          readOnly={!editable}
          value={currentTitle}
          style={{width}}
          className={editable && 'nodrag'}
          onChange={onChangeTitleInput}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlurTitle}
        />
        <TitleSuffix>{suffix}</TitleSuffix>
        {hasMinimized && <FontAwesomeIcon className="title-modify-btn" icon={faPen} onClick={onClickTitleIcon} />}
      </WidgetTitle>
      <ButtonGroup>
        {children}

        {onLock && (
          <Button variant="none" onClick={onClickLock}>
            <Tooltip content="Lock Toggle" place="bottom">
              <FontAwesomeIcon icon={locked ? faLock : faLockOpen} size="xl" color={iconColor} />
            </Tooltip>
          </Button>
        )}
        {onConfig && (
          <Button variant="none" onClick={onConfig}>
            <Tooltip content="Settings" place="bottom">
              <FontAwesomeIcon icon={faGear} size="xl" color={iconColor} />
            </Tooltip>
          </Button>
        )}
        {hasDocking && (
          <Button variant="none" onClick={onClickDocking}>
            <Tooltip content={docked ? 'Undock Widget' : 'Dock Widget'} place="bottom">
              <FontAwesomeIcon icon={faSidebarFlip} size="xl" color={iconColor} />
            </Tooltip>
          </Button>
        )}
        <IconDivider />
        {hasMinimized && (
          <Button variant="none" disabled={docked} onClick={onClickMinimize}>
            <Tooltip content="Minimize" place="bottom">
              <FontAwesomeIcon icon={faWindowMinimize} />
            </Tooltip>
          </Button>
        )}
        <Button
          variant="none"
          disabled={docked}
          onClick={(e) => {
            e.stopPropagation();
            setIsShowStack(!isShowStack);
          }}
        >
          {hasStackOrder && (
            <Tooltip content="Stack Order" place="bottom">
              <FontAwesomeIcon icon={faBringForward} size="xl" color={iconColor} />
            </Tooltip>
          )}
          {isShowStack && (
            <StackOrderLayer onClick={(e) => e.stopPropagation()}>
              <FontAwesomeIcon
                icon={faBringFront}
                name="bring-front"
                className={classNames(isTop && 'disabled')}
                size="xl"
                color={iconColor}
                onClick={(e) => onClickStackOrder(e, 'FRONT')}
              />
              <FontAwesomeIcon
                icon={faBringForward}
                name="bring-forward"
                className={classNames(isTop && 'disabled')}
                size="xl"
                color={iconColor}
                onClick={(e) => onClickStackOrder(e, 'FORWARD')}
              />
              <FontAwesomeIcon
                icon={faSendBackward}
                name="send-backward"
                className={classNames(isBottom && 'disabled')}
                size="xl"
                color={iconColor}
                onClick={(e) => onClickStackOrder(e, 'BACKWARD')}
              />
              <FontAwesomeIcon
                icon={faSendBack}
                name="send-back"
                className={classNames(isBottom && 'disabled')}
                size="xl"
                color={iconColor}
                onClick={(e) => onClickStackOrder(e, 'BACK')}
              />
            </StackOrderLayer>
          )}
        </Button>
        <Button variant="none" className="close-button" onClick={onClickClose}>
          <FontAwesomeIcon icon={faClose} size="xl" color={iconColor} />
        </Button>
      </ButtonGroup>
    </Container>
  );
}

export default WidgetHeader;
