import React, {memo, ReactElement, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {IHmbConfig} from 'components/pc/widgets/hmb/type';
import {defaultHmbConfig} from 'components/pc/widgets/hmb/const';
import {toSignificantDigit} from 'utils/number-utils';
import WidgetContainer from 'components/pc/widgets/parts/WidgetContainer';
import {WidgetActionPanel, WidgetBody, WidgetHeader} from 'components/pc/widgets/parts';
import {faTable} from '@fortawesome/pro-light-svg-icons';
import BasicSpinner from 'components/common/BasicSpinner';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import {NodeProps, useReactFlow} from 'reactflow';
import {IHmbWidgetData, IWidgetNodeData} from 'components/pc/types';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {IHmbSheetCellInfo} from 'components/spreadsheet/types';
import {CellChange, ChangeSource} from 'handsontable/common';
import {hbmWidgetCalcFunc} from 'components/pc/widgets/hmb/hmb-functions';
import useHmbNormalSpreadSheetHandler from 'components/pc/widgets/hmb/useHmbNormalSpreadSheetHandler';
import HmbSpreadsheet from 'components/spreadsheet/HmbSpreadsheet';
import styled from 'styled-components';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {DatabaseHierarchy, INodeInfo} from 'api/data-types';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';
import {DataContext} from 'api/DataProvider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, SwitchForm} from 'components/forms';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import classnames from 'classnames';
import HmbSettingPanelRevision from 'components/pc/widgets/hmb/HmbSettingPanelRevision';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {ISize} from 'components/common/types';

const Wrapper = styled.div`
  width: 100%;
  height: 100%; //calc(100% - 1px);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectNodesButton = styled(Button)`
  white-space: nowrap;
`;
const SelectTagButtonLabel = styled.span`
  margin-left: 6px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;

const UpdateTimeDisplay = styled.div`
  font-size: 14px;
  color: #888;
  align-self: center;
  margin-right: 15px;
  flex-shrink: 0;
`;
const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #98a9d5;

  &.active {
    color: #6287e8;
  }
`;

function HmbRevisionWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const {globalSettingsState, availableDatabaseHierarchyList} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  const [config, setConfig] = useState<IHmbConfig>(defaultHmbConfig);
  const spreadSheetMetaDataState = useState<IHandsonTableSpreadSheetData>(null);
  const hiddenFeatureState = useState<string[]>([]);
  const [hiddenFeatureList, setHiddenFeatureList] = hiddenFeatureState;
  const isHideComponentsState = useState(false);
  const [isHideComponentRows] = isHideComponentsState;
  const [spreadsheetMetaData, setSpreadSheetMetaData] = spreadSheetMetaDataState;
  const handsontableRef = useRef<any>(null); // unknown, object 둘다 안됨 HotTableClass (타입스크립트 에러 해결 안되서 any 일단 처리)
  const cellInfoState = useState<IHmbSheetCellInfo[][]>([[null, null]]);
  const [cellInfo, setCellInfo] = cellInfoState;
  const [nodeInfo, setNodeInfo] = useState<INodeInfo[]>([]);
  const tagSelectorInfoState = useState<IHmbSheetCellInfo>(null);
  const [tagSelectorInfo, setTagSelectorInfo] = tagSelectorInfoState;
  const selectedTagsState = useState<string[][]>([]);
  const isShowWidgetModalState = useState(false);
  const [updateTime, setUpdateTime] = useState<number>(0);
  const [apiLoading, setApiLoading] = useState<number>(0);
  const [widgetConfigModal, setWidgetConfigModal] = isShowWidgetModalState;
  const [separatorRowIdx, setSeparatorRowIdx] = useState(-1);
  const {onContextMenuPane, onKeyDownCell, beforeOnCellMouseDown} = useHmbNormalSpreadSheetHandler(
    id,
    spreadSheetMetaDataState,
    cellInfoState,
    tagSelectorInfoState,
    handsontableRef,
    selectedTagsState,
    isShowWidgetModalState,
    separatorRowIdx,
    setHiddenFeatureList
  );
  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 1});

  const {setNodes} = useReactFlow();
  /**
   * table 만들어질때 hook 등록되서 ref를 통해 참조를 넘겨줘야함
   */
  const dataRef = useRef(null);
  dataRef.current = spreadsheetMetaData;
  const nodeInfoRef = useRef(null);
  nodeInfoRef.current = nodeInfo;
  const cellInfoRef = useRef(null);
  cellInfoRef.current = cellInfo;
  const configRef = useRef(null);
  configRef.current = config;
  const latestNodeDataRef = useRef(null);
  latestNodeDataRef.current = latestNodeHandler.latestResult;
  const separatorRowIdxRef = useRef(null);
  separatorRowIdxRef.current = separatorRowIdx;

  useEffect(() => {
    if (data?.metaData) {
      const {
        list,
        descriptions,
        cellInfo,
        nodeInfos,
        hiddenFeature,
        config,
        hmbTableData,
        latestNodeData,
        separatorIdx,
        isInitialized
      } = data.metaData as IHmbWidgetData;
      /**
       * MIGRATION-3 HMB 구버전 저장해놓은 파일 기본동작하도록 변경  (Start)
       */
      if (list || descriptions) {
        const convert: INodeInfo[] = list.map((item) => ({
          database: 'model_fcc',
          hierarchy: ['FCC', 'Streams'],
          name: item.stream
        }));
        latestNodeHandler.renewSubscribe(id, convert, true);

        const constFeatures = [
          'Temperature',
          'Pressure',
          'MassFlowrate',
          'MolarFlowrate',
          'VolumeFlowrate',
          'MassBalance'
        ];

        const hmbCellInfo: IHmbSheetCellInfo[] = convert.map((item) => ({
          ...item,
          checked: constFeatures.map((feature) => ({
            database: item.database,
            hierarchy: [item.database, ...item.hierarchy, item.name],
            name: feature
          }))
        }));

        setSpreadSheetMetaData({
          colHeaders: false,
          rowHeaders: false,
          data: hbmWidgetCalcFunc.getDefaultTableData(),
          rendererType: 'HmbDataRenderer',
          readOnly: true,
          fixedColumnsStart: 2,
          fixedRowsTop: 1
        });
        setCellInfo([[null, null, ...hmbCellInfo]]);

        if (isInitialized !== null && isInitialized) {
          setApiLoading(1);
        }

        return;
      }
      /**
       * MIGRATION-3 HMB 구버전 저장해놓은 파일 기본동작하도록 변경 (End)
       */
      if (config) {
        setConfig(config);
      }
      if (hiddenFeature) {
        setHiddenFeatureList(hiddenFeature || []);
      }
      if (cellInfo) {
        setCellInfo(cellInfo || [[null, null]]);
      }
      if (hmbTableData) {
        setSpreadSheetMetaData(hmbTableData);
      }
      if (nodeInfos) {
        setNodeInfo(nodeInfos);
        if (config?.autoUpdate) {
          latestNodeHandler.renewSubscribe(id, nodeInfos, true);
        }
      }
      if (latestNodeData) {
        latestNodeHandler.onLoadLatestNodeResult(latestNodeData);
      }
      if (!isNaN(separatorIdx)) {
        setSeparatorRowIdx(separatorIdx);
      }
    } else {
      setSpreadSheetMetaData({
        colHeaders: false,
        rowHeaders: false,
        data: hbmWidgetCalcFunc.getDefaultTableData(),
        rendererType: 'HmbDataRenderer',
        readOnly: true,
        fixedColumnsStart: 2,
        fixedRowsTop: 1
      });
    }
  }, []);

  /**
   *
   * @param database
   * @param rest
   * @param hierarchyJson
   */
  useEffect(() => {
    if (availableDatabaseHierarchyList?.length === 0 || !availableDatabaseHierarchyList) return;

    const selectedStreams = cellInfo?.[0]?.filter((item) => item?.name && item?.database);
    const subscribeTarget = [...(latestNodeHandler.latestResult[id] || [])].filter((item) => {
      const split = item.key.split(', ');
      const [database, ...rest] = split;
      return (
        selectedStreams.some((stream) => stream.database === database) &&
        selectedStreams.some((stream) => rest.includes(stream.name))
      );
    });

    /**
     * [{
     *   key: 'fcc_model-FCC-Streams-Feed-Temperature',
     *   value: [0.01, 0.04, 0.07, 0.07,],
     *   updateTime: [17098023, 17099023,17010023, 17011023]
     * }]
     */
    if (subscribeTarget.length === 0) {
      // console.log('>>>>');
      // setSpreadSheetMetaData((prev) => (prev ? {...prev, data: hbmWidgetCalcFunc.getDefaultTableData()} : prev));
      return;
    }
    if (subscribeTarget) {
      const renewTime = Math.max(...(subscribeTarget.map((item) => item?.updateTime) || []));
      setUpdateTime((prev) => {
        if (renewTime > prev) {
          setApiLoading(0);
          return renewTime;
        } else {
          return prev;
        }
      });

      const propertyList = subscribeTarget
        .filter((item) => !item.key.includes('Components'))
        .filter((item) => !hiddenFeatureList.some((hiddenFeature) => item.key.includes(hiddenFeature)))
        .map((raw) => {
          const split = raw.key.split(', ');
          return {
            ...raw,
            name: split[split.length - 1]
          };
        })
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      const componentList = subscribeTarget
        .filter((item) => item.key.includes('Components'))
        .filter(() => !isHideComponentRows)
        .filter((item) => !hiddenFeatureList.some((hiddenFeature) => item.key.includes(hiddenFeature)))
        .map((raw) => {
          const split = raw.key.split(', ');
          return {
            ...raw,
            name: split[split.length - 1]
          };
        })
        .sort((a, b) => {
          // if (a.name < b.name) return -1;
          // if (a.name > b.name) return 1;
          // return 0;
          const numA = parseInt(a.name);
          const numB = parseInt(b.name);
          return numA - numB;
        });
      /**
       * hidden component delete
       * isHide => components = []
       */

      const componentNameList = Array.from(new Set(componentList.map((item) => item.name)));
      const propertyNameList = Array.from(new Set(propertyList.map((item) => item.name)));

      const propertyRows = [];
      for (let i = 0; i < propertyNameList.length; i++) {
        const targetProperty = propertyNameList[i];
        /**
         *  todo:
         * 첫번째 stream unit 을 사용하고,
         * 다른 unit 을 사용하는 stream이 존재한다면 unit 변한이 필요
         */
        const db = selectedStreams?.[0]?.database; // model-ff
        const hierarchy = selectedStreams?.[0]?.hierarchy; //
        const streamName = selectedStreams?.[0]?.name;

        const unit = getUnit(db, [...hierarchy, streamName, targetProperty], availableDatabaseHierarchyList);
        const newRow = [targetProperty, unit || ''];

        for (let j = 0; j < selectedStreams.length; j++) {
          const targetStream = selectedStreams[j];
          const findItem = propertyList.find(
            (item) => item.key.includes(targetStream.name) && item.key.includes(targetProperty)
          );
          const v = String(
            toSignificantDigit(findItem?.value?.[findItem?.value?.length - 1][1], globalSettings.significantDigit)
          );
          newRow.push(v || '');
        }
        propertyRows.push(newRow);
      }

      const componentRows = [];
      for (let i = 0; i < componentNameList.length; i++) {
        const targetComponent = componentNameList[i];

        const db = selectedStreams?.[0]?.database; // model-ff
        const hierarchy = selectedStreams?.[0]?.hierarchy; //
        const streamName = selectedStreams?.[0]?.name;

        const unit = getUnit(
          db,
          [...hierarchy, streamName, 'Components', targetComponent],
          availableDatabaseHierarchyList
        );

        const newRow = [targetComponent, unit || ''];

        for (let j = 0; j < selectedStreams.length; j++) {
          const targetStream = selectedStreams[j];
          const findItem = componentList.find(
            (item) => item.key.includes(targetStream.name) && item.key.includes(targetComponent)
          );
          const v = String(
            toSignificantDigit(findItem?.value?.[findItem?.value?.length - 1][1], globalSettings.significantDigit)
          );
          newRow.push(v || '');
        }
        componentRows.push(newRow);
      }

      const newCells = [];
      const firstRow = ['Name', 'Unit'].concat(selectedStreams?.map((item) => item.name));
      newCells.push(firstRow);

      for (let r of propertyRows) {
        newCells.push(r);
      }

      const seperatorRow = ['Components', ''].concat(selectedStreams?.map((item) => ''));
      newCells.push(seperatorRow);

      for (let r of componentRows) {
        newCells.push(r);
      }
      setSeparatorRowIdx(propertyRows.length - 1 + 2);
      setSpreadSheetMetaData((item) => (item ? {...item, data: newCells} : item));
    }
  }, [
    latestNodeHandler.latestResult,
    cellInfo,
    hiddenFeatureList,
    isHideComponentRows,
    availableDatabaseHierarchyList
  ]);

  const afterChange = (changes: CellChange[], source: ChangeSource) => {
    switch (source) {
      case 'updateData': {
        break;
      }
      default: {
        /**
         * react 리렌더링 (Handsontable 이 이미 어레이참조를 통하여서 data 값은 변화하였지만, react 가 변화를 감지하지 못해서 리렌더링이 일어나지않기떄문에, setState
         */
        setSpreadSheetMetaData((prev) => ({...prev}));
        return;
      }
    }

    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node?.data,
                metaData: {
                  ...node?.data?.metaData,
                  hmbTableData: dataRef.current,
                  cellInfo: cellInfoRef.current,
                  nodeInfos: nodeInfoRef.current,
                  config: configRef.current,
                  latestNodeData: latestNodeDataRef.current,
                  separatorIdx: separatorRowIdxRef.current
                }
              }
            }
          : node
      )
    );
  };

  const onClose = (): void => {
    setTagSelectorInfo(null);
  };

  const onSelect = (checked: string[]) => {
    const parsedData = checked.map((item) => JSON.parse(item));
    const truncatedData = parsedData.map((item) => {
      const streamsIndex = item.indexOf('Streams');
      return item.slice(0, streamsIndex + 2);
    });
    const uniqueData = Array.from(new Set(truncatedData.map((d) => JSON.stringify(d)))).map((d) => JSON.parse(d));
    const nodeInfos: INodeInfo[] = uniqueData.map((item) => {
      const [database, ...hierarchy] = item as string[];
      return {
        database,
        name: hierarchy.pop(),
        hierarchy
      };
    });

    const checkedRefined = nodeInfos.map((item) => {
      /**
       * ['model_fcc', 'FCC', 'Streams','Effluent']
       * ['model_fcc', 'FCC', 'Streams', 'Effluent', 'Pressure']
       */
      const checked: INodeInfo[] = parsedData
        .filter((checkedItem) => {
          return (
            checkedItem.some((t) => item.name === t) &&
            checkedItem.some((t) => item.database === t) &&
            checkedItem.some((t) => item.hierarchy.some((h) => h === t))
          );
        })
        .map((stringArray) => {
          const [database, ...rest] = stringArray;
          const hierarchy = rest.slice(0, -1);
          const name = rest[rest.length - 1];
          return {
            database,
            hierarchy,
            name
          };
        });
      return {
        ...item,
        checked
      };
    });

    const newCellInfo = [[null, null].concat(checkedRefined)];
    // Transform the data
    const changedNumber = latestNodeHandler.renewSubscribe(id, nodeInfos, Boolean(config.autoUpdate));

    if (nodeInfos.length === 0) {
      setSpreadSheetMetaData((prev) => (prev ? {...prev, data: hbmWidgetCalcFunc.getDefaultTableData()} : prev));
    }
    setCellInfo(newCellInfo);
    setApiLoading(changedNumber);
    setNodeInfo(nodeInfos);
    if (!cellInfoRef.current || !nodeInfoRef.current) {
      return;
    }
  };

  const onClickAddStreamButton = (row: number, col: number) => {
    const selectedRangeArray = [[row, row, col, col]];
    setTagSelectorInfo({
      row,
      col,
      selectedRangeArray
    });
  };

  const onChangeLive = () => {
    setConfig((prev) => ({...prev, autoUpdate: !config?.autoUpdate}));
    latestNodeHandler.renewSubscribe(id, nodeInfo, !config?.autoUpdate);
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {
                  ...node.data.metaData,
                  config: {
                    ...config,
                    autoUpdate: !config?.autoUpdate
                  }
                }
              }
            }
          : node
      )
    );
  };

  const onChangeConfig = (cfg: IHmbConfig, action?: string): void => {
    const mergedCfg = {...config, ...cfg};
    switch (action) {
      case 'updateTimer':
        latestNodeHandler.changeDuration(mergedCfg.updateIntervalUnit * Number(mergedCfg.updateIntervalVal));
        break;
      default:
    }
    setConfig(mergedCfg);
  };

  const selectedNodes = useMemo(() => {
    return cellInfo?.[0]
      .filter((item) => item)
      .map((item) => item?.checked)
      .flat(1)
      .map((item) => [item?.database, ...item?.hierarchy, item?.name]);
  }, [cellInfo]);

  const nodeSelectorOptions = useMemo(() => {
    return {searchStrings: ['Streams'], selectableUntilBelowSearchString: true, useEndPoint: true};
  }, []);

  const ref = useRef(null);
  const [widgetSize, setWidgetSize] = useState<ISize>();

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const [target] = entries;
        setWidgetSize({width: target.contentRect.width, height: target.contentRect.height});
      });
      observer.observe(ref.current);
    }
  }, []);

  return (
    <WidgetContainer {...rest} docked={data.docked} data={data} type="HmbWidget">
      <WidgetHeader
        {...rest}
        type="HmbWidget"
        id={id}
        title={
          data.customizedTitle ? data.title : getWidgetTitle({type: 'HmbRevisionWidget', titleData: nodeInfo, data})
        }
        suffix="- HMB Table"
        icon={faTable}
        // hasDocking
        docked={data.docked}
        onConfig={() => setWidgetConfigModal(true)}
      />
      <WidgetActionPanel>
        <Wrapper>
          <Left>
            <SelectNodesButton
              variant="dark"
              height={34}
              onClick={(e) => {
                e.stopPropagation();
                onClickAddStreamButton(0, 0);
              }}
            >
              <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
              <SelectTagButtonLabel>Select Stream Nodes</SelectTagButtonLabel>
            </SelectNodesButton>
            <BasicSpinner isShow={apiLoading > 0} margin="0 10px" size="md" type="inline" />
          </Left>
          <Right>
            {widgetSize?.width > 420 && (
              <UpdateTimeDisplay>
                Last updated {updateTime !== 0 && dayjs(updateTime).format('YYYY-MM-DD HH:mm:ss')}
              </UpdateTimeDisplay>
            )}
            <SwitchForm
              name={'hmb-live-' + id}
              id={'hmb-live-switch-' + id}
              label={<SwitchLabel className={classnames(config?.autoUpdate && 'active')}>Live</SwitchLabel>}
              onChange={onChangeLive}
              checked={config?.autoUpdate}
              checkedValue={true}
              uncheckedValue={false}
              // disabled={true}
            />
          </Right>
        </Wrapper>
      </WidgetActionPanel>
      <WidgetBody ref={ref} actionMenuHeight={60}>
        <Wrapper
          onKeyDown={(e) => {
            if (e.key === 'Delete') {
              e.stopPropagation();
              onKeyDownCell(e);
            }
          }}
        >
          {spreadsheetMetaData?.data?.length > 0 && (
            <HmbSpreadsheet
              spreadsheetMetaData={spreadsheetMetaData}
              cellInfo={cellInfo}
              seperatorRowIdx={separatorRowIdx}
              onContextMenuPane={onContextMenuPane}
              handsontableRef={handsontableRef}
              onKeyDownCell={onKeyDownCell}
              afterChange={afterChange}
              beforeOnCellMouseDown={beforeOnCellMouseDown}
              onClickAddStreamButton={onClickAddStreamButton}
              isHideComponentsState={isHideComponentsState}
            />
          )}
          {Boolean(tagSelectorInfo) && (
            <NodeSelectorRevision
              title="Select Streams"
              onClose={onClose}
              onSelect={onSelect}
              selectedNodes={selectedNodes}
              options={nodeSelectorOptions}
            />
          )}
        </Wrapper>
      </WidgetBody>
      <WidgetConfigLayer title="Settings" isShow={widgetConfigModal} onClose={() => setWidgetConfigModal(false)}>
        <HmbSettingPanelRevision
          config={config}
          id={id}
          hiddenFeatureState={hiddenFeatureState}
          onChangeConfig={onChangeConfig}
          closeConfig={() => setWidgetConfigModal(false)}
        />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}

export default memo(HmbRevisionWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});

const getUnit = (database: string, rest: string[], hierarchyJson: DatabaseHierarchy[]) => {
  let targetDatabase = hierarchyJson.find((item) => item.database === database);
  let searchNode = targetDatabase.data;
  let unit: string;
  for (let i = 0; i < rest.length; i++) {
    if (i === rest.length - 1) {
      unit = searchNode?.find((item) => item?.name === rest[i])?.unit;
    }
    searchNode = searchNode?.find((item) => item?.name === rest[i])?.subnode;
  }
  return unit;
};
