import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {faTableCells} from '@fortawesome/pro-solid-svg-icons';
import React, {
  CSSProperties,
  KeyboardEvent,
  memo,
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import {NodeProps, useReactFlow} from 'reactflow';
import {IDatasheetLocalDbWidgetData, IDataSheetWidgetData, IWidgetNodeData} from 'components/pc/types';
import {
  IDatasheetLocalDbWidgetSubjectInfoDetail,
  ILocalDatabaseDetail,
  IPythonEditorWidgetSubjectInfoDetail,
  LocalDatabaseContext
} from 'api/LocalDatabaseProvider';
import LocalDbSpreadsheet from 'components/spreadsheet/LocalDbSpreadsheet';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {CellChange, ChangeSource} from 'handsontable/common';
import {dataSheetContextMenuFnc, spreadsheetUtilFnc} from 'components/spreadsheet/spreadsheetl-functions';
import {IDatasheetWidgetAutoRunConfig} from 'components/pc/widgets/datasheet/types';
import DatasheetWidgetActionPanel from 'components/pc/widgets/datasheet/DatasheetWidgetActionPanel';
import {IPythonEditorOutflowResult} from 'components/pc/widgets/PythonEditorWidget';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import useProcessCanvasMenuCheckUsable from 'components/menu/pulldown/useProcessCanvasMenuCheckUsable';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import WidgetIconModeHeader from 'components/pc/widgets/parts/WidgetIconModeHeader';
import useLatestTagHandler from 'hooks/useLatesetTagHandler';
import useNormalSpreadSheetEffect from 'components/spreadsheet/hooks/useNormalSpreadSheetEffect';
import useNormalSpreadSheetHandler from 'components/spreadsheet/hooks/useNormalSpreadSheetHandler';
import NodeSelector from 'components/pc/node-selector/NodeSelector';
import {TreeCategory} from 'api/DataProvider';
import {languages} from 'monaco-editor';
import ITagData = languages.html.ITagData;
import {ISize} from 'components/common/types';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';
import {INodeInfo} from 'api/data-types';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function DatasheetLocalDbWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const {updateSubjectInfo, enrollmentInfo, contentInfo} = useContext(LocalDatabaseContext);
  const spreadSheetMetaDataState = useState<IHandsonTableSpreadSheetData>({
    colHeaders: null,
    rowHeaders: null,
    colWidth: 100,
    data: spreadsheetUtilFnc.getArr(5, 5, ''),
    rendererType: 'NormalDataRenderer',
    initialized: false
  });
  const [spreadsheetMetaData, setSpreadsheetMetaData] = spreadSheetMetaDataState;
  const selectedRowIdxState = useState<number>(0);
  const [selectedRowIdx, setSelectedRowIdx] = selectedRowIdxState;
  const autoRunConfigState = useState<IDatasheetWidgetAutoRunConfig>({
    intervalValue: 1,
    intervalUnit: 1000 * 60,
    isRepeat: false
  });
  const [autoRunConfig, setAutoRunConfig] = autoRunConfigState;
  const [createTimestamp, setCreateTimestamp] = useState<number>(0);
  // const [connectedDbInfo, setConnectedDbInfo] = useState<ILocalDatabaseDetail[]>([]);
  const [prevConnectedDbInfo, setPrevConnectedDbInfo] = useState<ILocalDatabaseDetail[]>([]);
  const [readOnlyMode, setReadOnlyMode] = useState(false);

  const maxRow = spreadSheetMetaDataState[0]?.data?.length || 0;
  const {setNodes} = useReactFlow();
  const handsontableRef = useRef<any>(null);
  const dataRef = useRef(null);
  dataRef.current = spreadsheetMetaData;

  const focusedIdxRef = useRef(null);
  focusedIdxRef.current = selectedRowIdx;

  const [isSubscriberBatchRun, setIsSubscriberBatchRun] = useState(false);
  const prevConnectedDbInfoRef = useRef(null);
  prevConnectedDbInfoRef.current = prevConnectedDbInfo;
  const createTimestampRef = useRef(null);
  createTimestampRef.current = createTimestamp;
  const subjectRef = useRef(null);
  const [connectedDatasheetSubjectInfo, setConnectedDatasheetSubjectInfo] =
    useState<IDatasheetLocalDbWidgetSubjectInfoDetail>();

  const [connectedPythonEditorSubjectInfo, setConnectedPythonEditorSubjectInfo] =
    useState<IPythonEditorWidgetSubjectInfoDetail>();

  const [pythonRunOutflowResultArr, setPythonRunOutflowResultArr] = useState<IPythonEditorOutflowResult[]>([]);

  const tagSelectorInfoState = useState<ISpreadSheetCellInfo>(null);
  const [tagSelectorInfo, setTagSelectorInfo] = tagSelectorInfoState;
  const selectedTagsState = useState<string[][]>([]);
  const [selectedNodes, setSelectedNodes] = selectedTagsState;

  const iconModeState = useState<CSSProperties>(null);
  const [iconMode, setIconMode] = iconModeState;
  const isShowWidgetModalState = useState(false);

  const cellInfoState = useState<ISpreadSheetCellInfo[][]>(null);
  const [cellInfo, setCellInfo] = cellInfoState;
  // const latestTagHandlerForTable = useLatestTagHandler({type: 'latest_count', latest_count: 1});
  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 1});

  // useNormalSpreadSheetEffect(data, id, spreadSheetMetaDataState, cellInfoState, latestNodeHandler);

  useEffect(() => {
    if (data?.metaData) {
      const loadData = data?.metaData as IDataSheetWidgetData;
      if (loadData?.spreadsheetMetaData) {
        setSpreadsheetMetaData(loadData?.spreadsheetMetaData);
      }
      if (loadData?.cellInfo) {
        setCellInfo(loadData?.cellInfo);
      }
    } else {
      setSpreadsheetMetaData({
        colHeaders: null,
        rowHeaders: null,
        colWidth: 100,
        data: spreadsheetUtilFnc.getArr(5, 5, ''),
        rendererType: 'NormalDataRenderer'
      });
    }
  }, []);

  const {latestResult, renewSubscribe, disconnectSubscribe} = latestNodeHandler;

  useEffect(() => {
    const fnc = () => {
      if (!cellInfo) return;
      console.log(cellInfo);
      // const convert: INodeInfo[] = list.map((item) => ({
      //   database: 'model_fcc',
      //   hierarchy: ['FCC', 'Streams'],
      //   name: item.stream
      // }));
      const nodeInfos: INodeInfo[] = [];
      for (let i = 0; i < cellInfo?.length; i++) {
        for (let j = 0; j < cellInfo[i].length; j++) {
          if (cellInfo[i][j]) {
            nodeInfos.push({
              database: cellInfo[i][j]?.database,
              hierarchy: cellInfo[i][j]?.hierarchy,
              name: cellInfo[i][j]?.name
            });
          }
        }
      }
      // INodeInfo
      renewSubscribe(id, nodeInfos, true);
    };
    fnc();
  }, [id, cellInfo]);

  useEffect(() => {
    const res = latestResult[id];
    if (res) {
      console.log(res);
      console.log(cellInfo);
      setSpreadsheetMetaData((prev) => {
        const newData = [...prev.data.map((item) => [...item])];
        for (let i = 0; i < cellInfo?.length; i++) {
          for (let j = 0; j < cellInfo[i].length; j++) {
            if (cellInfo[i][j]) {
              const database = cellInfo[i][j]?.database;
              const hierarchy = cellInfo[i][j]?.hierarchy;
              const name = cellInfo[i][j]?.name;
              // const [category, ...rest] = cellInfo[i][j]?.tag;
              const tagKey = [database.toLowerCase(), ...hierarchy, name].join(', ');
              if (newData?.length > i && newData?.[i]?.length > j) {
                newData[i][j] = res.find((item) => item.key === tagKey)?.value?.[0]?.[1];
              }
            }
          }
        }
        console.log(newData);
        return {...prev, data: newData};
      });
    }
  }, [latestResult, id, cellInfo]);

  useEffect(() => {
    return () => {
      disconnectSubscribe(id);
    };
  }, []);

  const {onSelectTags, onContextMenuPane, onKeyDownCell, beforeOnCellMouseDown} = useNormalSpreadSheetHandler(
    spreadSheetMetaDataState,
    cellInfoState,
    tagSelectorInfoState,
    handsontableRef,
    selectedTagsState,
    isShowWidgetModalState
  );

  const next = async (): Promise<boolean> => {
    /**
     *  go to next Row Index
     */
    let success = false;
    setSelectedRowIdx(function (prev) {
      let nextRowIdx = -1;
      if (autoRunConfig.isRepeat) {
        if (prev + 2 > maxRow) {
          nextRowIdx = 0;
        } else {
          nextRowIdx = prev + 1;
        }
      } else {
        if (prev + 2 > maxRow) {
          nextRowIdx = prev;
        } else {
          nextRowIdx = prev + 1;
        }
      }
      if (prev !== nextRowIdx) {
        success = true;
      }
      return nextRowIdx;
    });
    return success;
  };

  const changeReadOnly = async (readOnly: boolean) => {
    /**
     *  Repeat disabled
     *  Auto update disabled (setTimeInterval function return)
     */
    setAutoRunConfig((prev) => ({...prev, intervalValue: 0, isRepeat: false}));
    setReadOnlyMode(readOnly);
    return true;
  };

  subjectRef.current = {
    dbData: spreadsheetMetaData?.data,
    focusedRowIdx: selectedRowIdx,
    next,
    changeReadOnly
  };

  useEffect(() => {
    updateSubjectInfo(id, 'DatasheetLocalDbWidget', subjectRef);
  }, []);

  useEffect(() => {
    const widgetSubscriptionInfo = enrollmentInfo?.[id];
    const datasheetSubjectInfoArray = [];
    const pythonEditorSubjectInfoArray = [];
    if (widgetSubscriptionInfo) {
      widgetSubscriptionInfo?.forEach((info) => {
        const widgetSubjectInfo = contentInfo?.[info.id];
        if (info?.type === 'PythonEditorWidget') {
          pythonEditorSubjectInfoArray.push(widgetSubjectInfo);
        } else if (info?.type === 'DatasheetLocalDbWidget') {
          datasheetSubjectInfoArray.push(widgetSubjectInfo);
        }
      });
    }
    setConnectedDatasheetSubjectInfo(datasheetSubjectInfoArray?.[0]);
    setConnectedPythonEditorSubjectInfo(pythonEditorSubjectInfoArray?.[0]);
  }, [enrollmentInfo, contentInfo]);

  useEffect(() => {
    if (data?.metaData) {
      const {
        autoRunConfig,
        cellInfo,
        spreadsheetMetaData,
        selectedRowIdx,
        connectedDbInfo,
        pythonRunOutflowResultArr,
        iconMode
      } = data?.metaData as IDatasheetLocalDbWidgetData;
      if (spreadsheetMetaData) {
        setSpreadsheetMetaData({...spreadsheetMetaData, initialized: true});
      }
      if (autoRunConfig) {
        setAutoRunConfig(autoRunConfig);
      }
      if (cellInfo) {
        setCellInfo(cellInfo);
      }
      setSelectedRowIdx(selectedRowIdx || 0);
      setPrevConnectedDbInfo(connectedDbInfo || []);
      setPythonRunOutflowResultArr(pythonRunOutflowResultArr || []);
      setIconMode(iconMode || null);
    } else {
      setSpreadsheetMetaData((prev) => ({...prev, initialized: true}));
    }
  }, []);

  useEffect(() => {
    /**
     * 변경예정
     */
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {
                  ...node?.data?.metaData,
                  autoRunConfig,
                  spreadsheetMetaData: dataRef.current,
                  selectedRowIdx: selectedRowIdx,
                  cellInfo: cellInfo,
                  pythonRunOutflowResultArr
                }
              }
            }
          : node
      )
    );
  }, [autoRunConfig, selectedRowIdx, pythonRunOutflowResultArr, cellInfo]);

  const afterChange = (changes: CellChange[], source: ChangeSource) => {
    switch (source) {
      case 'updateData': {
        break;
      }
      default: {
        /**
         * react 리렌더링 (Handsontable 이 이미 어레이참조를 통하여서 data 값은 변화하였지만, react 가 변화를 감지하지 못해서 리렌더링이 일어나지않기떄문에, setState
         */

        break;
      }
    }
    if (dataRef.current?.initialized) {
      // renewLocalDbInfo(dataRef.current?.data);
    }
  };

  const convertPythonOutflowResultTo2dArray = (outflowResultArr: IPythonEditorOutflowResult[]) => {
    const origin = [...outflowResultArr];
    const headers = ['timestamp'];
    const rows = [];
    const timestampIndexMap = {};

    origin.forEach((item) => {
      const {timestamp, outflowResult} = item;
      if (!(timestamp in timestampIndexMap)) {
        timestampIndexMap[timestamp] = rows.length;
        rows.push([timestamp]);
      }
      Object.entries(outflowResult).forEach(([key, value]) => {
        if (!headers.includes(key)) {
          headers.push(key);
        }
        const rowIndex = timestampIndexMap[timestamp];
        const headerIndex = headers.indexOf(key);
        while (rows[rowIndex].length < headerIndex) {
          rows[rowIndex].push(null); // Fill with null to match the header length
        }
        rows[rowIndex][headerIndex] = value;
      });
    });

    rows.forEach((row) => {
      while (row.length < headers.length) {
        row.push(null);
      }
    });
    setSpreadsheetMetaData((prev) => ({...prev, data: [headers, ...rows]}));
  };

  useEffect(() => {
    if (connectedPythonEditorSubjectInfo?.ref?.current?.pythonRunOutflowResult) {
      setPythonRunOutflowResultArr((prev) => {
        const next = prev.concat([connectedPythonEditorSubjectInfo?.ref?.current?.pythonRunOutflowResult]);
        convertPythonOutflowResultTo2dArray(next);
        return next;
      });
    }
  }, [connectedPythonEditorSubjectInfo?.ref?.current?.pythonRunOutflowResult]);

  const onClose = () => {
    setTagSelectorInfo(null);
    setSelectedNodes([]);
  };

  const onClickIconModeIcon = () => {
    setIconMode(function (prevState) {
      let prevStyle = null;
      if (prevState) {
        setNodes((nodes) =>
          nodes.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: {
                    ...item.data,
                    metaData: {...item.data.metaData, iconMode: null}
                  },
                  style: prevState
                }
              : item
          )
        );
      } else {
        setNodes((nodes) => {
          prevStyle = nodes.find((item) => item.id === id).style;
          return nodes.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: {
                    ...item.data,
                    metaData: {...item.data.metaData, iconMode: prevStyle}
                  },
                  style: {
                    width: 240,
                    height: 144,
                    maxWidth: 240,
                    maxHeight: 144,
                    minWidth: 240,
                    minHeight: 144
                  }
                }
              : item
          );
        });
      }
      return prevStyle;
    });
  };

  const categoryList = ['Model', 'Plant', 'Weather', 'Commodity'] as TreeCategory[];
  const [selectedTags, setSelectedTags] = selectedTagsState;
  console.log(selectedTags);

  const ref = useRef(null);
  const [widgetSize, setWidgetSize] = useState<ISize>();

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const [target] = entries;
        setWidgetSize({width: target.contentRect.width, height: target.contentRect.height});
      });
      observer.observe(ref.current);
    }
  }, []);

  // console.log(spreadsheetMetaData);
  // console.log(cellInfo);

  return (
    <WidgetContainer {...rest} data={data} type="DatasheetLocalDbWidget" iconMode={Boolean(iconMode)}>
      <WidgetHeader
        type="SpreadsheetWidget"
        icon={faTableCells}
        id={id}
        // hasDocking
        docked={data.docked}
        title={
          data.customizedTitle ? data.title : getWidgetTitle({type: 'DatasheetLocalDbWidget', titleData: '', data})
        }
        suffix="- Datasheet"
      />
      {Boolean(iconMode) && (
        <WidgetIconModeHeader
          title={
            data.customizedTitle ? data.title : getWidgetTitle({type: 'DatasheetLocalDbWidget', titleData: '', data})
          }
        />
      )}
      <WidgetActionPanel height={!iconMode ? 60 : 123}>
        <DatasheetWidgetActionPanel
          widgetSize={widgetSize}
          iconMode={Boolean(iconMode)}
          selectedRowIdxState={selectedRowIdxState}
          autoRunConfigState={autoRunConfigState}
          // subjectDbInfo={subjectDbInfo}
          next={next}
          readOnlyMode={readOnlyMode}
          maxRow={maxRow}
          isSubscriberBatchRun={isSubscriberBatchRun}
          onClickIconModeIcon={onClickIconModeIcon}
        />
      </WidgetActionPanel>
      <WidgetBody ref={ref}>
        <Wrapper
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Delete') {
              e.stopPropagation();
              onKeyDownCell(e);
            }
          }}
        >
          <LocalDbSpreadsheet
            selectedRowIdx={selectedRowIdxState[0]}
            spreadsheetMetaData={spreadsheetMetaData}
            cellInfo={cellInfo}
            onContextMenuPane={onContextMenuPane}
            handsontableRef={handsontableRef}
            onKeyDownCell={onKeyDownCell}
            afterChange={afterChange}
          />
          {Boolean(tagSelectorInfo) && (
            <NodeSelectorRevision
              onClose={() => {
                setTagSelectorInfo(null);
                setSelectedTags([]);
              }}
              onSelect={onSelectTags}
              selectedNodes={selectedTags}
            />
          )}
        </Wrapper>
      </WidgetBody>
    </WidgetContainer>
  );
}

// export default memo(DatasheetLocalDbWidget, (prevProps, nextProps) => {
//   return prevProps.selected === nextProps.selected;
// });
export default DatasheetLocalDbWidget;
