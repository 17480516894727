import {memo, MouseEvent, RefObject, KeyboardEvent, Dispatch, SetStateAction} from 'react';
import {HotTable} from '@handsontable/react';
import styled from 'styled-components';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import NormalDataRenderer from 'components/spreadsheet/renderer/NormalDataRenderer';
import {CellChange, ChangeSource} from 'handsontable/common';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .handsontable {
    .wtHolder {
      &::-webkit-scrollbar {
        background: rgba(0, 0, 0, 0.08);
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
      }
    }
    td {
      padding: 0;
      > div {
        width: 100%;
        height: 100%;
      }
      &:before {
        pointer-events: none;
      }
    }
  }
`;

type IProps = {
  spreadsheetMetaData: IHandsonTableSpreadSheetData;
  cellInfo: ISpreadSheetCellInfo[][];
  handsontableRef: RefObject<any>;
  selectedRowIdx: number;
  onContextMenuPane?(event: MouseEvent, row: number, col: number): void;
  onKeyDownCell?(event: KeyboardEvent): void;
  afterChange?(changes: CellChange[], source: ChangeSource): void;
  beforeOnCellMouseDown?(event: globalThis.MouseEvent, coords: CellCoords, TD: HTMLTableCellElement): void;
};

function LocalDbSpreadsheet({
  spreadsheetMetaData,
  selectedRowIdx,
  cellInfo,
  handsontableRef,
  onContextMenuPane,
  onKeyDownCell,
  afterChange,
  beforeOnCellMouseDown
}: IProps) {
  const spreadsheetData = spreadsheetMetaData;
  // console.log(cellInfo);
  const cells = (row: number, column: number) => {
    if (cellInfo?.[row]?.[column]) {
      return {
        readOnly: true
      };
    }
    return {};
  };

  // const afterGetColHeader = (column: number, TH: HTMLTableCellElement, headerLevel: number) => {
  //   if (column > 0 && TH) {
  //     TH.setAttribute('title', TH.querySelector('span').textContent);
  //   }
  // };

  return (
    <Wrapper onContextMenu={(e) => e.preventDefault()}>
      <HotTable
        ref={handsontableRef}
        colHeaders={true}
        rowHeaders={(index) => {
          return String(index);
        }}
        data={spreadsheetData?.data}
        colWidths={spreadsheetData?.colWidth ?? 200}
        height="100%"
        autoRowSize={false}
        autoColumnSize={false}
        autoWrapRow={true}
        autoWrapCol={true}
        readOnly={spreadsheetData?.readOnly}
        // maxRows={spreadsheetData?.data?.length || 5}
        // maxCols={spreadsheetData?.data?.[0]?.length || 5}
        cells={cells}
        licenseKey="non-commercial-and-evaluation"
        // afterGetColHeader={afterGetColHeader}
        afterChange={afterChange}
        // afterOnCellMouseDown={afterOnCellMouseDown}
        beforeOnCellMouseDown={beforeOnCellMouseDown}
      >
        <NormalDataRenderer
          hot-renderer
          onContextMenuPane={onContextMenuPane}
          onKeyDownCell={onKeyDownCell}
          cellInfo={cellInfo}
          selectedRowIdx={selectedRowIdx}
        />
      </HotTable>
    </Wrapper>
  );
}

/**
 * cellInfo 가 되면 spreadsheetMetaData 또한 useEffect 통해서 업데이트가 되기때문에, cellInfo 변경되었을때 리렌더링되는 것을 방지
 */
export default memo(LocalDbSpreadsheet, (prevProps, nextProps) => {
  return (
    prevProps.spreadsheetMetaData === nextProps.spreadsheetMetaData &&
    prevProps.selectedRowIdx === nextProps.selectedRowIdx
  );
});
// export default NormalSpreadsheet;
