import {useRef, useState} from 'react';
import {INode, INodeDataReturn, INodeParams, INodeRecordParams} from 'api/data-types';
import {IChartStackInfo, ILoadSeriesParams, ISeries, ITimeSeriesLoader} from 'components/pc/widgets/timeseries/types';
import {colors} from 'common/colors';
import useApi from 'api/useApi';
import {DateObject} from 'react-multi-date-picker';
import {getDataType, IDataTypes} from 'components/pc/widgets/timeseries/timeseries-functions';

const getNodeParamList = (nodes: string[][], rangeOrCount: number | number[]): INodeParams[] => {
  // param 목록 구성
  return nodes?.map((node) => {
    const [database, ...rest] = node;
    const merged: INodeParams = {
      node: [...rest],
      database
    };
    if (Array.isArray(rangeOrCount)) {
      merged.time_range = [...rangeOrCount];
    } else {
      merged.latest_count = rangeOrCount;
    }
    return merged;
  });
};

const getSortedNodes = (data: number[][], type: IDataTypes): number[] => {
  if (!data) return [];
  // timestamp 관련 정제
  const refined = data.map((point) => {
    // const [timestamp, value] = node.data;
    // return [timestamp * 1000, Number(type === 'datetime' ? value % (3600 * 24) : value)];
    point[0] = point[0] * 1000;
    point[1] = Number(point[1]);
    if (type === 'datetime') {
      point[1] = point[1] % (3600 * 24);
    }
    return point;
  });
  // console.log('>>> refined', refined);
  // 평균, 최소, 최대 값등을 구하기 위한 sort
  return refined
    .reduce((acc, point) => {
      acc.push(point[1]);
      return acc;
    }, [])
    .sort((a, b) => a - b);
};

const getSeriesList = (nodeList: INode[], prevSeriesList?: ISeries[]): ISeries[] => {
  return nodeList.map((item, index) => {
    const prevItem = prevSeriesList?.[index];

    const {node, records} = item;
    const type = getDataType(node);

    const sorted = getSortedNodes(records, type);
    const tagStart = node.length < 3 ? 0 : 2;
    const color = prevSeriesList ? prevItem?.color : colors.chart[index % colors.chart.length];
    const parallel = prevSeriesList ? prevItem?.parallel : 0;
    const active = prevSeriesList ? prevItem?.active : true;

    const refined = {
      keys: node,
      flattenKeys: node.join('-'),
      name: [...node].splice(tagStart, 3).join('-'),
      color,
      avg: sorted?.length > 0 ? sorted.reduce((acc, curr) => Number(acc) + Number(curr)) / sorted.length : undefined,
      max: sorted?.[sorted.length - 1] ? Number(sorted?.[sorted.length - 1]) : undefined,
      min: sorted?.[0] ? Number(sorted?.[0]) : undefined, // String 일 시에 Highchart 인식 X
      data: records ?? [],
      active,
      parallel
    };
    return refined as ISeries;
  });
};

function useTimeSeriesLoader(): ITimeSeriesLoader {
  const api = useApi();

  // range default 설정
  // live 의 range 는 상태를 저장하지 않는다.(주의)
  const now = new DateObject();
  const end = now.unix;
  const start = now.subtract(7, 'day').unix;
  const [range, setRange] = useState<number[]>([start, end]);

  // nodes default 설정 (empty array)
  const [nodes, setNodes] = useState<string[][]>([]);

  // live 상태 default 설정
  const [isLive, setIsLive] = useState(false);

  // isLoading 상태 default 설정
  const [isLoading, setIsLoading] = useState(false);
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);

  // last update time 상태 default 설정 (undefined)
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>();

  // series 상태 default 설정 (empty array)
  const [series, setSeries] = useState<ISeries[]>([]);
  // yAxis 의 stack 상태를 저장
  const [stackInfo, setStackInfo] = useState<IChartStackInfo[]>([{parallel: 0, stacked: true, checked: true}]);
  // const recordList = useRef<INode[]>(null)
  // chart 등에서 실제 화면에서 표시하는 데이터
  const [nodeList, setNodeList] = useState<INode[]>([]);
  const [liveData, setLiveData] = useState<INode[]>([]);

  const timerId = useRef<NodeJS.Timeout>(null);

  const getNodeList = async (params: INodeRecordParams): Promise<INode[]> => {
    const result = await api.post<INodeDataReturn>('/node_manage/get_node_data_list', params);
    const {success, data} = result;
    if (success) {
      return data;
    }
    return null;
  };

  const load = ({nodeList, range, isLive, dataUpdateOnly, savedSeries, withWidgetSpinner}: ILoadSeriesParams): void => {
    // if (nodes?.length === 0) return;
    const n = nodeList || nodes || null;
    if (n === null) return;

    // console.log('> 엔이야', n);
    setIsLoading(true);
    if (withWidgetSpinner) setIsWidgetLoading(true);

    const params = {node_infos: getNodeParamList(n, range)};
    getNodeList(params).then((resultNodeList) => {
      // console.log('nodeList>> ', nodeList);
      setNodeList(resultNodeList);

      // 새 nodes 목록을 받지 않았다면, 현재 series 를 전달하여 color, parallel 등의 설정을 유지하도록 함
      let prevSeries = undefined;
      if (isLive) {
        prevSeries = savedSeries;
      } else if (!Boolean(nodeList)) {
        prevSeries = series;
      }
      // const prevSeries = !Boolean(nodeList) ? series : undefined;

      const list = getSeriesList(resultNodeList, prevSeries);
      setSeries(list);
      // if (dataUpdateOnly) {
      //   setSeries((prev) => prev.map((channel, index) => ({...channel, data: nodeList[index].records})));
      // } else {
      //   const list = getSeriesList(nodeList);
      //   setSeries(list);
      // }
      // const list = getSeriesList(nodeList);
      // setSeries(list);

      // 다른 컴포넌트에서도 현재 상태를 제대로 표시하기 위해 series 이외의 상태 업데이트

      // live 라면 계속 동작
      if (isLive) {
        // if (list.length) setLiveData(list?.map((item) => item.data));
        /*console.log(
          '>>> live',
          list.map((item) => item.data)
        );*/
        // range 를 latest_count 로 변경해주고 다시 param 구성
        params.node_infos = getNodeParamList(n, 1);
        const loadLive = (): void => {
          setIsLoading(true);
          getNodeList(params).then((nodeList) => {
            setLiveData(nodeList);
            setIsLoading(false);
            // console.log('live series>>', nodeList);
            // setSeries((prev) => {
            //   prev.map(item => item.data.concat(liveList))
            // })
            setLastUpdateTime(new Date());
          });

          if (timerId) clearTimeout(timerId.current);
          timerId.current = setTimeout(loadLive, 5000);
        };
        loadLive();

        // console.log('>>>>useEffect nodes, range, isLive ', nodes, range);
      } else {
        if (timerId) clearTimeout(timerId.current);
        // range 는 live 가 아닐 경우에만 저장
        setRange(range);
      }

      setNodes(n);
      if (!isLive) setRange(range);
      setIsLive(isLive);
      setIsLoading(false);
      if (withWidgetSpinner) setIsWidgetLoading(false);
    });
  };

  const removeSeries = (node: string[]): void => {
    // console.log('>>>> remove series id', node);
    const key = node.join('-');
    setSeries(series.filter((item) => item.flattenKeys !== key));
  };

  const refreshSeries = (): void => {
    setSeries((prev) => [...prev]);
  };

  const updateSeries = (seriesList: ISeries[]): void => {
    setSeries(seriesList);
  };

  const clearSeries = (): void => {
    setSeries([]);
  };

  const reorder = (list: string[]): void => {
    const obj = {};
    series.forEach((item) => {
      obj[item.flattenKeys] = item;
    });
    const ordered = list.map((id) => obj[id]);
    setSeries(ordered);
  };

  return {
    isLoading,
    setIsLoading,
    isLive,
    setIsLive,
    isWidgetLoading,
    range,
    setRange,
    nodes,
    setNodes,
    lastUpdateTime,

    series,
    setSeries,
    nodeList,

    stackInfo,
    setStackInfo,

    liveData,
    load,
    removeSeries,
    refreshSeries,
    updateSeries,
    clearSeries,
    reorder
  };
}

export default useTimeSeriesLoader;
