import React, {PropsWithChildren, ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';
import NoticeDisplay from 'components/common/notice/NoticeDisplay';
import classNames from 'classnames';

const Container = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
  margin: auto;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: ${({theme}) => theme.action.menu.transition};
  user-select: none;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(39, 37, 70, 0.8);
    //backdrop-filter: blur(3px);
    transition: ${({theme}) => theme.action.menu.transition};
  }
  &:hover {
    background-color: #272546;
    &.frozen {
      background-color: transparent;
    }
  }

  &.frozen {
    &:before {
      background-color: rgba(59, 59, 59, 0.3);
    }
    &:hover {
      background-color: rgba(59, 59, 59, 0.4);
    }
  }

  &.process-canvas,
  &.mpfd,
  &.widget,
  &.copilot,
  &.mpfd-files {
    width: calc(100% - ${({theme}) => theme.action.menu.width}px);
    left: ${({theme}) => theme.action.menu.width}px;
  }
`;

type IProps = PropsWithChildren & {
  isFreeze?: boolean;
};

/**
 * ProcessCanvasPullDownMenu 또는 MetaPfdPullDownMenu 과 합성하여
 * 위치, 크기, 등의 시각적 설정만을 담당하는 컴포넌트
 * @param children
 * @param isFreeze
 * @constructor
 */
function PullDownMenu({children, isFreeze}: IProps): ReactElement {
  const {remoteAppMenu} = useContext(CommonContext);

  return (
    <Container className={classNames(remoteAppMenu?.id, {frozen: isFreeze})}>
      {children}
      {/*<NoticeDisplay />*/}
    </Container>
  );
}

export default PullDownMenu;
