import {MouseEvent, ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  > em {
    font-style: normal;
  }
`;
const Hierarchy = styled.span`
  color: #aaa;
  font-size: 0.9em;
`;
const Unit = styled.span`
  color: #296abe;
  font-size: 0.9em;
  margin-left: 0.3em;
`;

type IProps = {
  nodes: string[];
  unit?: string;
  hasContextMenu?: boolean;
};

function NodeNameDisplay({nodes, unit, hasContextMenu}: IProps): ReactElement {
  const [createContextMenu] = useContextMenu();
  const {addNotice} = useContext(CommonContext);

  const [nodePath, setNodePath] = useState('');
  const [nodeName, setNodeName] = useState('');

  const onContextMenu = (event: MouseEvent): void => {
    if (!hasContextMenu) return;

    const list = [
      {
        label: 'Add Widget',
        value: 'add-widget',
        children: [
          {label: 'HMB Widget', value: 'hmb-widget', disabled: true},
          {label: 'Spreadsheet Widget', value: 'spreadsheet-widget', disabled: true},
          {label: 'Time Series Widget', value: 'time-series-widget', disabled: true},
          {label: '3D Model Widget', value: '3d-model-widget', disabled: true}
        ]
      },
      {
        label: 'Get Tag',
        value: 'get-tag',
        callback() {
          console.log('===> get tag', nodeName);
        }
      },
      {
        label: 'Set Tag',
        value: 'set-tag',
        callback() {
          console.log('===> set tag', nodeName);
        }
      },
      {
        label: 'Copy Tag ID',
        value: 'copy-tag-id',
        callback() {
          navigator.clipboard
            .writeText(nodeName)
            .then(() => {
              addNotice({text: `Copied tag id ${nodeName}`});
            })
            .catch((err) => {});
          console.log('===> copy tag id', nodeName);
        }
      },
      {
        label: 'Paste Tag ID',
        value: 'paste-tag-id',
        callback() {
          console.log('===> paste tag id', nodeName);
        }
      },
      {
        label: 'Delete Tag',
        value: 'delete-tag',
        disabled: true,
        callback() {
          console.log('===> delete tag', nodeName);
        }
      },
      {
        label: 'Information',
        value: 'tag-information',
        callback() {
          console.log('===> information tag', nodeName);
        }
      }
    ];
    createContextMenu({event, list, title: nodeName});
  };

  useEffect(() => {
    const cloneKey = [...nodes];
    setNodeName(cloneKey.pop());
    setNodePath(cloneKey.join('-'));
  }, [nodes]);

  return (
    <Container onContextMenu={onContextMenu}>
      {nodePath && <Hierarchy>{nodePath}-</Hierarchy>}
      <em>{nodeName}</em>
      {unit && <Unit>({unit})</Unit>}
    </Container>
  );
}

export default NodeNameDisplay;
