import React, {ReactElement, useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AppContainer} from 'components/layout';
import {Button} from 'components/forms';
import styled from 'styled-components';
import {faArrowProgress, faDiagramProject} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import splashImage from '../assets/images/splash-image.png';
import {CommonContext} from 'components/common/CommonProvider';
import ApiHostTypeDisplay from 'components/common/ApiHostTypeDisplay';
// import packageInfo from '../../package.json';

const AppSplash = styled.div`
  width: 800px;
  height: 400px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.1);

  > a {
    margin: auto;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 34px;
`;

const MainHeader = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    margin-bottom: 10px;
  }
  span {
    font-size: 8px;
    margin-left: 1px;
    margin-bottom: 42px;
  }
`;

const MainBody = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    color: ${({theme}) => theme.color.dark};
  }
`;

const MainFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 0;

  span {
    color: ${({theme}) => theme.color.public};
    margin-bottom: 30px;
  }
`;

const ButtonWrap = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  button {
    font-size: 16px;
    font-weight: 300;
    padding: 0 20px;
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }
`;

const RightSide = styled.div`
  width: 400px;
  height: 400px;

  img {
    border-radius: 0 10px 10px 0;
  }
`;

function HomePage(): ReactElement {
  const {version} = useContext(CommonContext);
  const {state} = useLocation();
  const pcUrl = `/pc/${state?.pc ?? ''}`;
  const mpfdUrl = `/mpfd/${state?.mpfd ?? ''}`;

  return (
    <AppContainer type="home">
      <AppSplash>
        <LeftSide>
          <MainHeader>
            <h2>ProcessMetaverse™</h2>
          </MainHeader>
          <MainBody>
            {/*todo 문구 확정나면 받아서 수정 필요*/}
            <p>Welcome to ProcessMetaverse, where creativity meets technology.</p>
            <p>Our creations are safeguarded by copyright law. Join us in shaping the digital twin.</p>
          </MainBody>
          <MainFooter>
            <span>ver {version}</span>
            <ApiHostTypeDisplay />
            <ButtonWrap>
              <Link to={pcUrl}>
                <Button height={40}>
                  <FontAwesomeIcon icon={faDiagramProject} />
                  Process Canvas
                </Button>
              </Link>
              <Link to={mpfdUrl}>
                <Button height={40}>
                  <FontAwesomeIcon icon={faArrowProgress} /> Meta PFD
                </Button>
              </Link>
            </ButtonWrap>
          </MainFooter>
        </LeftSide>
        <RightSide>
          <img src={splashImage} alt="process metaverse" />
        </RightSide>
      </AppSplash>
    </AppContainer>
  );
}

export default HomePage;
