import React, {memo, ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateTimeSelectBar from 'components/pc/widgets/timeseries/DateTimeSelectBar';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import {BasicSpinner, ResizeHandle, MessageSpinner} from 'components/common';
import useDraggable from 'utils/useDraggable';
import TimeSeriesTable from 'components/pc/widgets/timeseries/TimeSeriesTable';
import TimeSeriesChart from 'components/pc/widgets/timeseries/TimeSeriesChart';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import useTimeSeriesLoader from 'components/pc/widgets/timeseries/useTimeSeriesLoader';
import {ITimeSeriesWidgetData} from 'components/pc/types';
import {DateObject} from 'react-multi-date-picker';
import LightningChartProvider from 'components/pc/widgets/timeseries/LightningChartProvider';

const TableArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  height: 0; // 임시처리
  overflow: hidden;
  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-top: 1px solid #fff;
`;
const SelectNodesButton = styled(Button)`
  white-space: nowrap;
`;
const SelectTagButtonLabel = styled.span`
  margin-left: 6px;
`;

function TimeSeriesWidget({data, id, ...rest}: NodeProps): ReactElement {
  const {setNodes} = useReactFlow();

  // drag 관련 ref 설정
  const boundaryRef = useRef(null);
  const dragHandleRef = useRef(null);
  const defaultPosition = {x: 0, y: 250};
  const defaultPadding = {top: 60, right: 0, bottom: 0, left: 0};
  const [dateTimePosition, onMouseDownResizeHandle, setInitialChartSize] = useDraggable(
    boundaryRef,
    dragHandleRef,
    defaultPosition,
    defaultPadding
  );

  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);
  const loader = useTimeSeriesLoader();

  // File 에 저장된 데이터로 최초 데이터 세팅
  useEffect(() => {
    console.log('time series widget >> data?.metaData', data?.metaData);
    if (data?.metaData) {
      const {tags, range, isLive, nodes, chartHeight, series, stackInfo} = data.metaData as ITimeSeriesWidgetData;

      setInitialChartSize({x: 0, y: chartHeight || 0});

      if (isLive) {
        // live 라면 nodes, isLive, range 정보를 가지고 현시점 데이터를 로드 함
        const now = new DateObject();
        const end = now.unix;
        const start = now.subtract(10, 'minutes').unix;
        loader.load({nodeList: nodes || tags, isLive, range: [start, end], savedSeries: series});
      } else if (series) {
        // live 가 아니라면 저장된 데이터를 이용해 화면 표시
        loader.setIsLive(isLive);
        loader.setRange(range);
        loader.setNodes(nodes || tags);
        loader.setSeries(series || []);
        loader.setStackInfo(stackInfo);
      } else {
        // live 도 아니고 저장된 series 도 없다면 이전 버전의 File
        loader.load({nodeList: nodes || tags, isLive, range});
      }
      /**
       * MIGRATION-4
       * 호환성을 유지할 필요가 적으므로 제거함 - 2024-09-08
       */
    }
  }, []); // data.selected 가 변경되는 경우에도 재호출 되므로 현재는 빈배열로 처리하고 차후 처리 필요

  // 상태 변경에 의한 react flow 데이터 세팅
  useEffect(() => {
    const {nodes, range, isLive, series, stackInfo} = loader;
    const metaData = {nodes, range, isLive, series, chartHeight: dateTimePosition.y, stackInfo};
    setNodes((nodes) => nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node)));
  }, [id, dateTimePosition, loader.nodes, loader.range, loader.series, loader.stackInfo]);

  /**
   * node selector 로부터 선택
   * @param checkedList
   */
  const onSelectNode = useCallback(
    (checkedList: string[]): void => {
      const nodes = checkedList.map((item) => JSON.parse(item));
      // console.log('>>> checkedList', checkedList, parsed);
      const {range} = loader;
      loader.load({nodeList: nodes, isLive: false, range});
    },
    [loader.range]
  );

  return (
    <WidgetContainer {...rest} docked={data.docked} data={data} type="TimeSeriesWidget">
      <WidgetHeader
        {...rest}
        type="TimeSeriesWidget"
        icon={data.icon}
        id={id}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'TimeSeriesWidget', titleData: [], data})}
        suffix="- Time Series"
        // hasDocking
        docked={data.docked}
      />
      <WidgetActionPanel disabled={isShowNodeSelector || loader.isWidgetLoading}>
        <DateTimeSelectBar
          id={id}
          loader={loader}
          direction={boundaryRef.current?.clientHeight - dateTimePosition.y > dateTimePosition.y ? 'bottom' : 'top'}
        >
          <SelectNodesButton variant="dark" height={34} onClick={() => setIsShowNodeSelector(true)}>
            <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
            <SelectTagButtonLabel>Select Nodes</SelectTagButtonLabel>
          </SelectNodesButton>
          <BasicSpinner isShow={loader.isLoading} margin="0 10px" size="md" type="inline" />
        </DateTimeSelectBar>
      </WidgetActionPanel>
      <WidgetBody ref={boundaryRef} actionMenuHeight={60}>
        <LightningChartProvider>
          <TimeSeriesChart docked={data.docked} height={dateTimePosition.y} loader={loader} />
        </LightningChartProvider>
        <TableArea>
          <ResizeHandle ref={dragHandleRef} onMouseDown={onMouseDownResizeHandle} />
          <TimeSeriesTable loader={loader} />
          {isShowNodeSelector && (
            <NodeSelectorRevision
              title="Select Nodes"
              selectedNodes={loader.nodes}
              onClose={() => setIsShowNodeSelector(false)}
              onSelect={onSelectNode}
            />
          )}
        </TableArea>
        <MessageSpinner isShow={loader.isWidgetLoading} type="overlay" size="lg" />
      </WidgetBody>
    </WidgetContainer>
  );
}

export default memo(TimeSeriesWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
