import {ITagData} from 'api/data-types';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import useProcessCanvasMenuCheckUsable from 'components/menu/pulldown/useProcessCanvasMenuCheckUsable';
import {Dispatch, KeyboardEvent, MouseEvent, RefObject, SetStateAction} from 'react';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
// import {HotTableClass} from '@handsontable/react';
import {dataSheetContextMenuFnc, spreadsheetUtilFnc} from 'components/spreadsheet/spreadsheetl-functions';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';

type IReturn = {
  onSelectTags(checked?: string[]): void;
  onContextMenuPane(event: MouseEvent, row: number, col: number): Promise<void>;
  onKeyDownCell?(event: KeyboardEvent): void;
  beforeOnCellMouseDown(event: globalThis.MouseEvent, coords: CellCoords, TD: HTMLTableCellElement): void;
};
function useNormalSpreadSheetHandler(
  spreadSheetMetaDataState: [IHandsonTableSpreadSheetData, Dispatch<SetStateAction<IHandsonTableSpreadSheetData>>],
  cellInfoState: [ISpreadSheetCellInfo[][], Dispatch<SetStateAction<ISpreadSheetCellInfo[][]>>],
  tagSelectorInfoState: [ISpreadSheetCellInfo, Dispatch<SetStateAction<ISpreadSheetCellInfo>>],
  handsontableRef: RefObject<any>,
  selectedTagsState: [string[][], Dispatch<SetStateAction<string[][]>>],
  isShowWidgetModalState: [boolean, Dispatch<SetStateAction<boolean>>]
): IReturn {
  const [spreadsheetMetaData, setSpreadsheetMetaData] = spreadSheetMetaDataState;
  const [cellInfo, setCellInfo] = cellInfoState;
  const [tagSelectorInfo, setTagSelectorInfo] = tagSelectorInfoState;
  const [, setSelectedTags] = selectedTagsState;
  const [, setIsShowWidgetModal] = isShowWidgetModalState;

  const onSelectTags = (checked: string[]): void => {
    console.log(checked);
    const newCellInfo =
      cellInfo ||
      spreadsheetUtilFnc.getArr(spreadsheetMetaData?.data.length, spreadsheetMetaData?.data[0].length, null);
    const newCellInfoRemake = newCellInfo.map((item) => [...item]);
    const selectedRangeArray = tagSelectorInfo?.selectedRangeArray;
    for (let i = 0; i < selectedRangeArray?.length; i++) {
      const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(selectedRangeArray[i]);
      for (let j = fromRow; j < toRow + 1; j++) {
        for (let k = fromCol; k < toCol + 1; k++) {
          const decode = JSON.parse(checked?.[0]);
          console.log(decode);
          const [database, ...hierarchy] = decode;
          newCellInfoRemake[j][k] = {
            name: hierarchy.pop(),
            hierarchy,
            database
          };
        }
      }
    }
    setCellInfo(newCellInfoRemake as ISpreadSheetCellInfo[][]);
  };

  const [createContextMenu] = useContextMenu();
  const {checkTagPaste} = useProcessCanvasMenuCheckUsable();

  const onContextMenuPane = async (event: MouseEvent, row: number, col: number) => {
    event.preventDefault();
    const cellTagInfo = cellInfo?.[row]?.[col] as ISpreadSheetCellInfo;
    const disabledPasteTagId = await checkTagPaste();
    const handsontableHotInstance = handsontableRef.current.hotInstance;
    let selectedRangeArray = handsontableHotInstance.getSelected();
    /**
     * 우클릭이 일어났지만, context menu 의 document.mousedown 에 의해서 선택이 되지 않았을 때, 현재 선택된 cell 을 수동으로 선택해줌
     */
    let columnQue = [];
    let rowQue = [];
    if (!selectedRangeArray) {
      handsontableRef.current.hotInstance.selectCell(row, col);
      selectedRangeArray = [[row, col, row, col]];
    }
    const selectedCellTagInfos: ISpreadSheetCellInfo[] = [];
    const selectedCells = [];
    for (let i = 0; i < selectedRangeArray?.length; i++) {
      const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(selectedRangeArray[i]);
      columnQue.push(fromCol);
      columnQue.push(toCol);
      rowQue.push(fromRow);
      rowQue.push(toRow);
      for (let j = fromRow; j < toRow + 1; j++) {
        for (let k = fromCol; k < toCol + 1; k++) {
          if (cellInfo?.[j]?.[k]) {
            selectedCellTagInfos.push({...cellInfo?.[j]?.[k]} as ISpreadSheetCellInfo);
          }
          selectedCells.push('-');
        }
      }
    }
    const [selectedColumnRanges, selectedColumnsIdx] = spreadsheetUtilFnc.deleteDuplicatesInSelectedRanges(columnQue);
    const [selectedRowRanges, selectedRowsIdx] = spreadsheetUtilFnc.deleteDuplicatesInSelectedRanges(rowQue);
    columnQue = selectedColumnRanges;
    rowQue = selectedRowRanges;

    const newCellInfo =
      cellInfo ||
      spreadsheetUtilFnc.getArr(spreadsheetMetaData?.data.length, spreadsheetMetaData?.data[0].length, null);
    const newCellInfo_2 = newCellInfo.map((item) => [...item]) as ISpreadSheetCellInfo[][];
    const newData = spreadsheetMetaData?.data.map((item: unknown[]) => [...item]);

    const list = [
      /*{
        label: 'Get Node',
        value: 'get-tag',
        callback() {
          dataSheetContextMenuFnc.getTag(selectedCellTagInfos);
        },
        disabled: !(selectedCellTagInfos?.length === 1)
      },
      {
        label: 'Set Node',
        value: 'set-tag',
        async callback() {
          await dataSheetContextMenuFnc.setTag(row, col, newCellInfo_2, setCellInfo);
        },
        disabled: disabledPasteTagId || !(selectedCells?.length === 1)
      },*/
      {
        label: 'Live Cell',
        value: 'set-tag-node-selector',
        callback() {
          setTagSelectorInfo({
            row,
            col,
            // tag: cellTagInfo?.tag,
            // name: cellTagInfo?.name,
            // unit: cellTagInfo?.unit,
            selectedRangeArray
          });
          if (cellTagInfo) {
            const node_string = [cellTagInfo?.database, ...(cellTagInfo?.hierarchy || []), cellTagInfo?.name];
            setSelectedTags(node_string ? [node_string] : []);
          } else {
            setSelectedTags([]);
          }
          console.log(cellTagInfo);
        }
      },
      /*{
        label: 'Copy Node ID',
        value: 'copy-tag-id',
        callback() {
          dataSheetContextMenuFnc.copyTag(selectedCellTagInfos);
        },
        disabled: !(selectedCellTagInfos?.length === 1)
      },
      {
        label: 'Paste Node ID',
        value: 'paste-tag-id',
        async callback() {
          await dataSheetContextMenuFnc.pasteTagId(
            row,
            col,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo
          );
        },
        disabled: disabledPasteTagId || !(selectedCells?.length === 1)
      },*/
      {
        label: 'Add Row Above',
        value: 'add-row-above',
        callback() {
          dataSheetContextMenuFnc.addRow(
            rowQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'above'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Add Row Below',
        value: 'add-row-below',
        callback() {
          dataSheetContextMenuFnc.addRow(
            rowQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'below'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Add Column Left',
        value: 'add-column-left',
        callback() {
          dataSheetContextMenuFnc.addColumn(
            columnQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'left'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Add Column Right',
        value: 'add-column-right',
        callback() {
          dataSheetContextMenuFnc.addColumn(
            columnQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'right'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Delete Column',
        value: 'delete-column',
        callback() {
          dataSheetContextMenuFnc.deleteColumn(
            newData,
            newCellInfo_2,
            selectedColumnsIdx,
            setSpreadsheetMetaData,
            setCellInfo
          );
        },
        disabled: selectedRangeArray?.length !== 1
      },
      {
        label: 'Delete Row',
        value: 'delete-row',
        callback() {
          dataSheetContextMenuFnc.deleteRow(
            newData,
            newCellInfo_2,
            selectedRowsIdx,
            setSpreadsheetMetaData,
            setCellInfo
          );
        }
      }
    ];
    createContextMenu({event, list});
  };

  const onKeyDownCell = (event: KeyboardEvent) => {
    event.preventDefault();
    let selectedRangeArray = handsontableRef.current.hotInstance.getSelected();
    if (event.key === 'Delete' && selectedRangeArray) {
      const newCellInfo =
        cellInfo ||
        spreadsheetUtilFnc.getArr(spreadsheetMetaData?.data.length, spreadsheetMetaData?.data[0].length, null);
      const newCellInfoRemake = newCellInfo.map((item) => [...item]);
      const newData = spreadsheetMetaData?.data.map((item: unknown[]) => [...item]);
      for (let i = 0; i < selectedRangeArray?.length; i++) {
        const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(
          selectedRangeArray[i]
        );
        for (let j = fromRow; j < toRow + 1; j++) {
          for (let k = fromCol; k < toCol + 1; k++) {
            newCellInfoRemake[j][k] = null;
            newData[j][k] = null;
          }
        }
      }
      setCellInfo(newCellInfoRemake as ISpreadSheetCellInfo[][]);
      setSpreadsheetMetaData((prev) => ({...prev, data: newData}));
    }
  };

  const beforeOnCellMouseDown = (event: globalThis.MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) => {
    const selected = handsontableRef.current.hotInstance.getSelected();

    if (cellInfo?.[coords.row]?.[coords.col]?.tag && TD?.ariaSelected && event?.button === 0) {
      if (selected?.length === 1 && selected[0][0] === selected[0][2] && selected[0][1] === selected[0][3])
        setIsShowWidgetModal(true);
    }
  };

  return {
    onSelectTags,
    onContextMenuPane,
    onKeyDownCell,
    beforeOnCellMouseDown
  };
}

export default useNormalSpreadSheetHandler;
